import React, {useRef, useState} from 'react';
import Footer from '../components/Footer';

import AccesoTexto from '../components/AccesoTexto';
import HeaderNew from '../components/HeaderNew';
const Acceso = () => {

  return (
    <div className="container p-0 bg-main text-center">
      <HeaderNew />
      <AccesoTexto />

      <Footer />
    </div>
  );
};

export default Acceso;