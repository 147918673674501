import React from 'react';

const PlayList = () => {
  return (
    <div>
      <h3>Playlist</h3>
      Próximamente
    </div>
  );
};

export default PlayList;