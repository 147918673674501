import React, { useEffect, useState, useRef } from 'react';
import resourcesServices from '../services/resourcesServices';
import { errorMessage } from '../errorHandler';
import UseProgressiveImage from './multimedia/UseProgressiveImage';

const MultimediaPublicoImageBG = () => {

  const [images, setImages] = useState([]);
  const [indiceImagen, setIndiceImagen] = useState(0);
  const [running, setRunning] = useState(false);
  const [esTest, setEsTest] = useState(false);
  const [randomBG, setRandomBG] = useState(0);

  const backgroundImageSrc = ['image-src-1', 'image-src-2', 'image-src-3', 'image-src-4']

  const closeRef = useRef(null);

  // Animacion de imagenes
  useEffect(() => {
    const cambiarImagen = () => {
      setIndiceImagen((prev) => (prev + 1) % images.length);
    };


    if(running){
      const intervalo = setInterval(cambiarImagen, 6*1000);
      return () => clearInterval(intervalo);
    }
  }, [images]);

  // useEffect(() => {
  //     console.log(images.length, indiceImagen, images[indiceImagen] && images[indiceImagen]["id"], images[indiceImagen] && images[indiceImagen]["imageUrl"] )
  // }, [indiceImagen]);

  // Carga de nuevas imagenes cada 1 minuto
  useEffect(() => {
    const cargaImagenes = () => {
      const idsVar = images.map(v => v["id"]).join(",")

      obtenerMensajes(`ids=${idsVar}`)
    };

    if(running){
      const intervalo = setInterval(cargaImagenes, 60*1000);
      return () => clearInterval(intervalo);
    }

  }, [images]);

  useEffect(() => {
    const randomImagen = Math.floor(Math.random() * 4);
    setRandomBG(randomImagen);

  }, []);

  useEffect(() => {
    if(esTest){
      obtenerMensajes()
      setRunning(true)
    }

  }, [esTest]);

  const comenzar = () => {
    obtenerMensajes()
    setRunning(true)
  }

  const cerrarModal = () => {
    closeRef.current.click();
  }

  const obtenerMensajes = async (otro = 'ids=') => {
    try {
        const data = await resourcesServices.obtenerResources(`multimedia_usuarios/multimediaPublico`, '', '', 1, otro + `${esTest ? '&test=true' : ''}`);

      if(data.data.length > 0){
        setImages(images.concat(data.data));
      }
    } catch (error) {
      errorMessage(error)
    }
  };


  return (
    <div className={`image-bg ${backgroundImageSrc[randomBG]}`}>
      <div className='content'>
      {
        running && images[indiceImagen] ? (
          <>
            {
              images[indiceImagen]['imageUrl'] && (
                <div className='only-image'>
                  {
                  images[indiceImagen]['nombre_usuario'].length>0 && (
                    <span className='text'>{images[indiceImagen]['nombre_usuario']}</span>
                  )
                  }
                  <UseProgressiveImage src={images[indiceImagen]['imageUrl']} ></UseProgressiveImage>
                </div>
              )
            }
            {/* {
              images[indiceImagen]['content_type'] === 'image' ? (
                <div className='only-image'>
                  {
                  images[indiceImagen]['received_message'].length>0 && (
                    <span className='text'>{images[indiceImagen]['received_message']}</span>
                  )
                  }
                  <span className='image' style={{transition: 'background 1s ease-in-out',
                background: `url(${images[indiceImagen]['imageUrl']}) center/contain no-repeat`,}}></span>
                </div>
              ) : (
                  images[indiceImagen]['received_message'].length>0 && (
                    <div className='only-text'>
                      <span>{images[indiceImagen]['received_message']}</span>
                    </div>
                  )
              )
            } */}
          </>
        ) : (
          <div className='main-message d-flex align-items-center text-center justify-content-center' style={{height:'100vh'}}>
              {/* <button onClick={() => comenzar()} className='btn btn-primary' type='button'>Comenzar</button> */}
              {/* <button onClick={() => setEsTest(true)} className='btn btn-primary ms-3' type='button'>Comenzar Test</button> */}
              <button data-bs-toggle="modal" data-bs-target="#videoSourceModal" className='btn btn-primary ms-3' type='button'>Origen de las imágenes</button>
          </div>
        )
      }
      </div>

      <div className="modal fade" id="videoSourceModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3>Comenzar</h3>

            </div>
            <div className="modal-footer">
              <button onClick={() => {comenzar(); cerrarModal()}} className='btn btn-primary' type='button'>Comenzar</button>
              <button type="button" onClick={() => {setEsTest(true); cerrarModal()}} className="btn btn-success" >Comenzar Test</button>
              <button type="button" className="btn btn-secondary" ref={closeRef} data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultimediaPublicoImageBG;