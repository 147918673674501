import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import TextControl from './customizedInputs/TextControl';
import TextAreaControl from './customizedInputs/TextAreaControl';
import SelectControl from './customizedInputs/SelectControl';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';
import AsyncSelect from 'react-select/async';

const EventoForm = ({marcaActual}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)

  const [evento, setEvento] = useState({});
  const [esNuevo, setEsNuevo] = useState(true);
  const [showPrecio, setShowPrecio] = useState(true);
  const [estados, setEstados] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [provinciaIdSeleccionada, setProvinciaIdSeleccionada] = useState("");
  const [saving, setSaving] = useState(false);
  const [ciudadSeleccionada, setCiudadSeleccionada] = useState({value: '', label: ''});

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(id){
      fetchEvento(id)
    } else {
      nuevoEvento()
    }

    obtenerEstados()
    obtenerProvincias()
  }, [id]);

  useEffect(() => {
    setCiudadSeleccionada({value: '', label: ''})
  }, [provinciaIdSeleccionada]);


  const fetchEvento = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('eventos', id);
      if(data.ciudad && data.ciudad.id){
        setProvinciaIdSeleccionada(data.ciudad.provincia_id)
        setCiudadSeleccionada({value: data.ciudad.id, label: data.ciudad.nombre})
      }

      setEvento(data);
      setShowPrecio(data.publicar_precio)
    } catch (error) {
      errorMessage(error)
    }
  };

  const nuevoEvento = async (id) => {
    try {
      const data = await resourcesServices.nuevoResource('eventos');
      setEvento(data);
      setShowPrecio(data.publicar_precio)
    } catch (error) {
      console.log('error', error)
    }
  };

  const guardarEvento = async () => {
    try {
      setSaving(true)
      let data;

      const copiaEvento = {...evento};

      if(copiaEvento.fecha_hora_inicio){
        copiaEvento.fecha_hora_inicio = copiaEvento.fecha_hora_inicio.replace(/T/ig, ' ');
      }

      if(copiaEvento.fecha_hora_hasta){
        copiaEvento.fecha_hora_hasta = copiaEvento.fecha_hora_hasta.replace(/T/ig, ' ');
      }


      if(esNuevo){
        data = await resourcesServices.crearResource('eventos', {evento: copiaEvento}, true);
        alert("Evento creado")
        navigate(`/cl/${marcaActual.url_name}/eventos`);
      } else {
        data = await resourcesServices.actualizarResource('eventos', copiaEvento.id, {evento: copiaEvento}, true);
        setEvento(data);
        setShowPrecio(data.publicar_precio)
        alert("Datos actualizados")
      }

      setSaving(false)

    } catch (error) {
      setSaving(false)
      errorMessage(error)
    }
  };

  const obtenerEstados = async () => {
    try {
      const data = await resourcesServices.obtenerResources('eventos/obtenerEstados');
      setEstados(data.data);
    } catch (error) {
      errorMessage(error)
    }
  };

  const obtenerProvincias = async () => {
    try {
      const data = await resourcesServices.obtenerResources('provincias');
      setProvincias(data.data);
    } catch (error) {
      errorMessage(error)
    }
  };

  const handleInput = (e) => {
    const { name, value, checked } = e.target;

    if(name === 'publicar_precio'){
      setShowPrecio(checked)
      setEvento({...evento,
        [name]: !evento.publicar_precio
      })
    } else {
      setEvento({...evento,
        [name]: value
      })
    }
  }

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setEvento({...evento,
      [name]: files[0]
    })
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    guardarEvento()
  }

  const handleInputNombreCiudad = async (newValue) => {
    if(newValue !== null){
      console.log(newValue)
      setCiudadSeleccionada(newValue)
      setEvento({...evento,
        ['ciudad_id']: newValue.value
      })
    }
  }

  const buscarCiudad = async(inputValue) => {
    try {
      if( inputValue.length < 3 ){
        return []
      }

      const results = await resourcesServices.obtenerResources(`ciudades`, '', inputValue, 1, `provincia_id=${provinciaIdSeleccionada}`);

      console.log(results)
      const ciudadOptions = results.data.map((ciudad) => ({
        value: ciudad.id,
        label: `${ciudad.nombre}`,
      }));

      // return [];
      return ciudadOptions;
    } catch (error) {
      console.error('Error al buscar ciudades:', error);
      return {};
    }
  }

  // https://stackoverflow.com/questions/54218351/changing-height-of-react-select-component
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: '450px',
      zIndex: 9999
    }),
  };

  return (
    <div>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <>
          <h3 className='mt-3'>Editar evento <BotonVolver /></h3>

          <div className="card">
            <div className="card-body px-0 pb-2 ms-3 me-3">
              <form onSubmit={handleFormSubmit}>
                <TextControl
                  value={evento.nombre}
                  name={'nombre'}
                  handleInput={handleInput}
                  />


                <div className='text-start'>
                  <label>Provincia</label>
                  <SelectControl
                      value={provinciaIdSeleccionada}
                      options={provincias}
                      name={`provincia`}
                      handleInput={(e) => setProvinciaIdSeleccionada(e.target.value)}
                      label={'Seleccione la provincia'}
                    />
                </div>

                <div className='mb-3 text-start'>
                  {/* <pre>{JSON.stringify(ciudadSeleccionada, null, 2)}</pre> */}
                  <label>Ciudad</label>
                  <AsyncSelect
                      className='text-center'
                      loadOptions={(e) => buscarCiudad(e)}
                      placeholder="Buscar por nombre..."
                      onChange={(e) => handleInputNombreCiudad(e)}
                      value={ciudadSeleccionada}
                      styles={customStyles}
                      isClearable
                    />
                </div>

                <TextControl
                  value={evento.lugar}
                  name={'lugar'}
                  handleInput={handleInput}
                  />

                <TextAreaControl
                    value={evento.descripcion}
                    name={'descripcion'}
                    height={'150px'}
                    handleInput={handleInput}
                  />

                <div className="input-group-text mb-3">
                  <input className="form-check-input mt-0"
                    type="checkbox"
                    name="publicar_precio"
                    checked={evento.publicar_precio || ''}
                    onChange={handleInput}
                    value=""
                    />
                  <label className="ms-3 form-check-label" htmlFor="flexCheckDefault">
                    Publicar Precio
                  </label>
                </div>

                {showPrecio && (
                  <div className='text-start'>
                  <div className="form-text">
                    Si el valor de la entrada es gratis, escriba 0 (cero)
                  </div>
                  <TextControl
                    value={evento.precio}
                    name={'precio'}
                    handleInput={handleInput}
                    type='number'
                    disabled={!showPrecio}
                    />
                  </div>
                )}

                <div className='row'>
                  <div className='col'>
                    <TextControl
                      value={evento.fecha_hora_inicio}
                      name={'fecha_hora_inicio'}
                      type={"datetime-local"}
                      handleInput={handleInput}
                    />
                  </div>
                  <div className='col'>
                    <TextControl
                        value={evento.fecha_hora_fin}
                        name={'fecha_hora_fin'}
                        type={"datetime-local"}
                        handleInput={handleInput}
                      />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <TextControl
                      value={evento.link_pago}
                      name={'link_pago'}
                      handleInput={handleInput}
                    />
                  </div>
                  <div className='col'>
                    <TextControl
                        value={evento.limite_link_pago}
                        name={'limite_link_pago'}
                        type={"datetime-local"}
                        handleInput={handleInput}
                      />
                  </div>
                </div>

                <div className="card mb-3">
                    <div className="card-body">
                    <h5 className="card-title">Suba una imagen (opcional)</h5>

                    {
                      evento.imageUrl && (
                        <img src={evento.imageUrl} className="img-fluid" alt={evento.nombre} />
                      )
                    }

                    <div className="mb-3">
                      <input placeholder="Adjunte su foto" className="form-control" onChange={handleFileChange} name="imagen" type="file" id="imagen" />
                    </div>

                  </div>
                </div>

                <div className="mb-3">
                  <SelectControl
                      value={evento.estado}
                      options={estados}
                      name={'estado'}
                      handleInput={handleInput}
                      label={'Seleccione el estado'}
                    />
                </div>

                <button disabled={saving} className="btn btn-primary mt-3" type="submit">
                  {saving && (<span className="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>)}
                  <span role="status">Guardar</span>
                </button>
              </form>
            </div>
          </div>
          </>

        )
      }
    </div>
  );
};

export default EventoForm;