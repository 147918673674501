import React, {useState, useEffect} from 'react';
import resourcesServices from '../services/resourcesServices';

const Whatsapp = () => {
  const [messages, setMessages] = useState([])

  useEffect(() => {
    obtenerMensajes()
  }, []);

  const obtenerMensajes = async (sortBy='') => {
    try {
      const data = await resourcesServices.obtenerResources('uuebs/getMessages');

      setMessages(data.data);
    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        alert(error.response.data.error)
      }
    }
  };


  return (
    <div className="container-fluid">
      <button type="button" className='' onClick={obtenerMensajes} >Actualizar</button>
      <div className='row'>
      {
        messages.map((message, index) => (
         <div className='col-sm-6 col-md-3 border' key={index}>
          <h3>{index+1} - {message.id}</h3>
          { message.imageUrl && <img src={message.imageUrl} className='img-fluid' /> }
          <p>{message.received_message}</p>
         </div>
        ))
      }
      </div>
    </div>
  );
};

export default Whatsapp;