import React, {useRef, useState, useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { errorMessage } from '../errorHandler';
import resourcesServices from '../services/resourcesServices';

const CompartirFoto = () => {
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();


  const [imageSrc, setImageSrc] = useState(null);
  const [imageGalery, setImageGalery] = useState(null);
  const [blobSrc, setBlobSrc] = useState(null);
  const [galerySource, setGalerySource] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  useEffect(() => {

    // localStorage.removeItem('idBailongo');
    const token = localStorage.getItem('idBailongo');
    if(!token){
      localStorage.setItem('idBailongo', generateRandomAlphanumeric(20));
    }

  }, []);

  const generateRandomAlphanumeric= (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const videoConstraints = {
    facingMode: isFrontCamera ? "user" : "environment"
  };


  const capture = useCallback(() => {
    setGalerySource(false)
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setBlobSrc(dataURLtoBlob(imageSrc))
  }, [webcamRef]);

  const buscarEnGaleria = () => {
    setGalerySource(true);
    fileInputRef.current.click()
  };

  const toggleCamera = () => {
    setIsFrontCamera(prevState => !prevState);
  };

  const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}

  const salir = () => {
    setImageSrc(null)
    setBlobSrc(null)
    navigate(`/acceso`);
  };

  const enviarFoto = async () => {
    try {
      if(imageSrc || imageGalery){
        const nombreBailongo = localStorage.getItem('nombreBailongo');
        const idBailongo = localStorage.getItem('idBailongo');

        const userName = window.prompt("Por favor, ingresa tu nombre:", nombreBailongo || '');

        if(userName){
          localStorage.setItem('nombreBailongo', userName);
        } else {
          alert("Si no ingresa su nombre no va a poder participar de posibles sorteos.")
          return false;
        }

        const formData = new FormData();
        if(galerySource){
          formData.append('multimedia_usuario[imagen]', imageGalery)
        } else {
          formData.append('multimedia_usuario[imagen]', blobSrc, 'photo.jpg')
        }
        formData.append('multimedia_usuario[id_web]', idBailongo)
        formData.append('multimedia_usuario[nombre_usuario]', userName)

        await resourcesServices.crearResource('multimedia_usuarios', formData, true);
        alert("Imagen enviada")

        setImageSrc(null)
      }

    } catch (error) {
      errorMessage(error)
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setImageGalery(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const nuevaFoto = () => {
    setImageSrc(null)
    setBlobSrc(null)
  };

  return (
    <div className="container p-0 bg-main position-relative vh-100">

      {imageSrc ? (
        <>
          <div className="position-absolute top-0 start-0 w-100 h-100">
            <img src={imageSrc} alt="Foto capturada" className="img-fluid w-100 h-100" style={{ objectFit: 'cover' }} />
          </div>
          <div className="position-absolute top-0 start-50 translate-middle-x mt-3">
            <div className="btn-group" role="group">
              <button onClick={() => enviarFoto()} className="btn btn-success">Enviar</button>
              <button onClick={() => nuevaFoto()} className="btn btn-danger">Nueva Foto</button>
            </div>

          </div>
          <div className="position-absolute bottom-0 start-50 translate-middle-x mb-5">
            <div>{localStorage.getItem('idBailongo')}</div>
          </div>
          </>
      ) : (
        <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        className="w-100 h-100"
        style={{ objectFit: 'cover' }}
      />
      <div className="position-absolute top-0 start-50 translate-middle-x mt-3">
        <div className="btn-group" role="group">
          <button onClick={() => salir()} className="btn btn-danger">Salir</button>
        </div>
      </div>

      <div className="position-absolute bottom-0 start-50 translate-middle-x mb-5">
        <div className="btn-group" role="group">
          <button onClick={capture} className="btn btn-primary">Tomar Foto</button>
          <button onClick={toggleCamera} className="btn btn-secondary">Rotar Cámara</button>

          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            ref={fileInputRef}
            className="d-none"
          />
          <button onClick={() => buscarEnGaleria()} className="btn btn-success">Galería</button>

        </div>
      </div>
        </>
      )
      }
    </div>
  );
};

export default CompartirFoto;