import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import TextControl from './customizedInputs/TextControl';
import TextAreaControl from './customizedInputs/TextAreaControl';
import SelectControl from './customizedInputs/SelectControl';
import BotonVolver from './BotonVolver';

const MensajeTemplateForm = ({marcaActual}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [esNuevo, setEsNuevo] = useState(true);
  const [mensaje, setMensaje] = useState({});
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosSeleccionados, setUsuariosSeleccionados] = useState([]);
  const [estados, setEstados] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [header, setHeader] = useState([]);
  const [estadoMensajesUsuarioActual, setEstadoMensajesUsuarioActual] = useState('');

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(id !== undefined){
      obtenerMensaje(id)
      obtenerMensajesUsuarios(id)
    } else {
      nuevoMensaje()
    }

    obtenerEstados();
    obtenerEventos();

  }, [id]);

  useEffect(() => {
    if(!esNuevo){
      obtenerMensajesUsuarios(id, '', '', 1, `estadoMensajesUsuario=${estadoMensajesUsuarioActual}`)
    }
  }, [estadoMensajesUsuarioActual]);


  const obtenerMensaje = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('mensajes', id);
      setMensaje(data);

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        console.log('error', error.response.data.error)
        // navigate(`/`);
      }
    }
  };

  const obtenerMensajesUsuarios = async (id, sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources(`mensajes/${id}/mensajes_usuarios`, sortBy, q, page, otro);
      if (page === 1) {
        setUsuarios(data.data);
      } else {
        setUsuarios([...usuarios, ...data.data]);
      }

      setHeader(data.headers);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const nuevoMensaje = async () => {
    try {
      const data = await resourcesServices.nuevoResource('mensajes');
      setMensaje(data);
    } catch (error) {
      console.log('error', error)
    }
  };


  const obtenerEstados = async () => {
    try {
      const data = await resourcesServices.obtenerResources('mensajes/obtenerEstados');
      setEstados(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const obtenerEventos = async () => {
    try {
      const data = await resourcesServices.obtenerResources('eventos');
      setEventos(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const guardarMensaje = async () => {
    try {
      let data;
      if(esNuevo){
        data = await resourcesServices.crearResource('mensajes', {mensaje: mensaje});

        alert("Datos actualizados")
        navigate(`/cl/${marcaActual.url_name}/mensaje-template/${data.id}`);
      } else {
        data = await resourcesServices.actualizarResource('mensajes', id, {mensaje: mensaje});
        setMensaje(data);

        alert("Datos actualizados")
      }


    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setMensaje({...mensaje,
        [name]: value
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    guardarMensaje()
  }

  return (
    <div>
        <main className="w-100 m-auto" style={{maxWidth: '660px'}}>
          <h5 className='mt-3'>Mensaje <BotonVolver /></h5>
          <form className='text-start'>
            <TextControl
                value={mensaje.titulo}
                name={'titulo'}
                handleInput={handleInput}
              />

            <div className="mb-3">
              <SelectControl
                  value={mensaje.evento_id}
                  options={eventos}
                  name={'evento_id'}
                  handleInput={handleInput}
                  label={'Seleccione el evento (opcional)'}
                />
            </div>

            <TextControl
                value={mensaje.template_name}
                name={'template_name'}
                handleInput={handleInput}
              />

            {/* <TextAreaControl
                value={mensaje.mensaje}
                name={'mensaje'}
                handleInput={handleInput}
                height={'200px'}
              /> */}

            {
              !esNuevo && (
                <div className="mb-3">
                  <SelectControl
                      value={mensaje.estado}
                      options={estados}
                      name={'estado'}
                      handleInput={handleInput}
                      label={'Seleccione el estado'}
                    />
                </div>
              )
            }

            <div className="">
              <button className="btn btn-primary mt-3" onClick={handleFormSubmit} type="submit">Guardar</button>
            </div>

          </form>

          {/* <pre>{JSON.stringify(usuariosSeleccionados, null, 2)}</pre> */}
          {
            mensaje && mensaje.titulo && (
              <div>
                <h5>Usuarios</h5>
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                  {
                    mensaje && mensaje["estadosMensajesUsuarios"].map((estado, index) => (
                      <div key={index} className='ms-1'>
                      <input
                        type="radio"
                        className="btn-check"
                        name={`radio${index}`}
                        id={`radio${index}`}
                        value={estado}
                        autoComplete="off"
                        checked={estadoMensajesUsuarioActual === estado}
                        onChange={(e) => setEstadoMensajesUsuarioActual(e.target.value)}
                        />
                      <label className="btn btn-outline-primary" htmlFor={`radio${index}`}>{estado}</label>
                      </div>
                    ))
                  }
                </div>
                <ul className="list-group list-group-flush mt-3">
                  {
                    usuarios.map((usuario, index) => (
                      <li key={index} className={`list-group-item text-start ${ usuario.estado === 'bloqueado' ? 'bg-warning' : '' }`}>
                        <div className='row'>
                          <div className='col-2'>
                            {usuario.estado}
                          </div>
                          <div className='col'>
                            {/* <span className={`badge text-bg-primary`}>{usuarios.length - index}</span> */}
                            <span className='ms-1'><b>{usuario && usuario.nombre_usuario} ({usuario.rol_usuario})</b></span>
                            <div className=''> {usuario && usuario.phone_usuario}
                              {
                                usuario.estado === 'bloqueado' && (
                                  <span className={`badge text-bg-danger ms-1`}>B</span>
                                )
                              }
                              {
                                (usuario.rol_usuario === 'Organizador' || usuario.rol_usuario === 'Admin') && (
                                  <span className={`badge text-bg-warning ms-1`}>Organizador</span>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  }
                </ul>

                {
                  header["total-pages"] !== header["current-page"] && (
                    <button onClick={()=>obtenerMensajesUsuarios(id, '', '', header["current-page"] + 1, `estadoMensajesUsuario=${estadoMensajesUsuarioActual}`)} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
                  )
                }

              </div>
            )
          }
        </main>
    </div>
  );
};

export default MensajeTemplateForm;