import React from 'react';
import { Link } from "react-router-dom";
import Politicas from '../components/Politicas';
import Footer from '../components/Footer';

const PoliticasWS = () => {
  return (
    <div className="container-fluid">
      <h1>Bailongo Social Club</h1>
      <p>
      <Link
          className="navbar-brand"
          to="/"
          >Home</Link>
      </p>
      <hr />
      <h2>Política de Privacidad</h2>

      <Politicas />
      <Footer />
    </div>
  );
};

export default PoliticasWS;