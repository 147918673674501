import React, {useState, useEffect, useContext, useRef} from 'react';
import { InputContext } from '../../contexts/InputContext';
import { useNavigate } from "react-router-dom";

const VotacionModal = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [countAction, setCountAction] = useState(0);
  const navigate = useNavigate();
  const closeRef = useRef(null);

  const redireccionar = () => {
    navigate(`/cl/${marcaActual.url_name}/usuario`);
    cerrarModal()
  }

  const cerrarModal = () => {
    setCountAction( current => current + 1);
    closeRef.current.click();
  }

  return (
    <div className="modal fade" id="votacionModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-body">
            <h3>Votación</h3>
          </div>
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={cerrarModal} >Cerrar</button>
            <button type="button" className="btn btn-secondary d-none" ref={closeRef} data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VotacionModal;