import React from 'react';
import BotonVolver from './BotonVolver';

const Juegos = () => {
  return (
    <div className='text-start'>
      <h3 className='mt-3'>Juegos <BotonVolver /></h3>

      <h5>Próximamente</h5>
    </div>
  );
};

export default Juegos;