import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import TextControl from './customizedInputs/TextControl';
import TextAreaControl from './customizedInputs/TextAreaControl';
import SelectControl from './customizedInputs/SelectControl';
import { InputContext } from '../contexts/InputContext';

const MarcaForm = ({marcaActual}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)
  const [esNuevo, setEsNuevo] = useState(true);
  const [marca, setMarca] = useState({});
  const [estados, setEstados] = useState([]);

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(id !== undefined){
      obtenerMarca(id)
    } else {
      nuevaMarca()
    }

    obtenerEstados();

  }, [id]);


  const obtenerMarca = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('marcas', id);
      setMarca(data);

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        console.log('error', error.response.data.error)
        // navigate(`/`);
      }
    }
  };

  const nuevaMarca = async () => {
    try {
      const data = await resourcesServices.nuevoResource('marcas');
      setMarca(data);
    } catch (error) {
      console.log('error', error)
    }
  };


  const obtenerEstados = async () => {
    try {
      const data = await resourcesServices.obtenerResources('marcas/obtenerEstados');
      setEstados(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };


  const guardarMarca = async () => {
    try {

      let data;
      if(esNuevo){
        data = await resourcesServices.crearResource('marcas', {marca: marca}, true);
        navigate(`/cl/${marcaActual.url_name}/marca/${data.id}`);
      } else {
        data = await resourcesServices.actualizarResource('marcas', id, {marca: marca}, true);
        if(data.url_name !== marcaActual.url_name){
          checkSignIn()
        }
        setMarca(data);

        alert("Datos actualizados")
      }


    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setMarca({...marca,
      [name]: files[0]
    })
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setMarca({...marca,
        [name]: value
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    guardarMarca()
  }


  return (
    <div>
        <main className="w-100 m-auto" style={{maxWidth: '660px'}}>
          <h5>Marca</h5>
          <form className='text-start'>
            <TextControl
                value={marca.nombre}
                name={'nombre'}
                handleInput={handleInput}
              />
            <div className="mb-3">
              <b>Código:</b> {marca.codigo}
            </div>

            <TextControl
                value={marca.url_name}
                name={'url_name'}
                handleInput={handleInput}
              />
            {
              !esNuevo && (
                <div className='my-3'>
                  <b>Link para tus usuarios:</b>
                  <p className="user-select-all mt-3">{`https://bailongo.club/cl/${marca.url_name}`}</p>
                  <p className="user-select-all mt-3">{`https://uueb.site/cl/${marca.url_name}`}</p>
                  {/* <input type="text" className="form-control user-select-all" value={`https://bailongo.club/cl/${marca.url_name}`} /> */}
                  {/* <input type="text" className="form-control mt-3 user-select-all" value={`https://uueb.site/cl/${marca.url_name}`} /> */}
                </div>
              )
            }

            <div className="card mb-3">
                <div className="card-body">
                <h5 className="card-title">Suba una imagen (opcional)</h5>

                {
                  marca.imageUrl && (
                    <img src={marca.imageUrl} className="img-fluid" alt={marca.nombre} />
                  )
                }

                <div className="mb-3">
                  <input placeholder="Adjunte su foto" className="form-control" onChange={handleFileChange} name="imagen" type="file" id="imagen" />
                </div>

              </div>
            </div>



            {
              !esNuevo && (
                <>
                {
                  authInformation.rol === "Admin" ? (
                  <div className="mb-3">
                  <SelectControl
                      value={marca.estado}
                      options={estados}
                      name={'estado'}
                      handleInput={handleInput}
                      label={'Seleccione el estado'}
                    />
                </div>

                ) : (
                  <div className="mb-3">
                  <b>Estado:</b> {marca.estado}
                </div>
                )}
                </>
              )
            }

            <div className="">
              <button className="btn btn-primary mt-3" onClick={handleFormSubmit} type="submit">Guardar</button>
            </div>

          </form>

          {/* <pre>{JSON.stringify(usuariosSeleccionados, null, 2)}</pre> */}
        </main>
    </div>
  );
};

export default MarcaForm;