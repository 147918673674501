import apiClient from '../apiClient';

const resourcesServices = {
  obtenerResources: async (resource, sortBy='', q='', page=1, otro='') => {
    try {
      const response = await apiClient.get(`/${resource}?query=${q}&sortBy=${sortBy}&page=${page}&${otro}`);

      const headers = {
        'page-items': parseInt(response.headers['page-items']),
        'total-count': parseInt(response.headers['total-count']),
        'total-pages': parseInt(response.headers['total-pages']),
        'current-page': parseInt(response.headers['current-page']),
        }

      return {data: response.data, headers: headers};
    } catch (error) {
      throw error;
    }
  },
  obtenerResourcePorId: async (resource, id) => {
    try {
      const response = await apiClient.get(`/${resource}/${id}`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  actualizarResource: async (resource, id, params, isMultipart = false) => {
    try {

      let headers = {}
      if(isMultipart){
        headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      }

      const response = await apiClient.put(`/${resource}/${id}`, params, headers);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  crearResource: async (resource, params, isMultipart = false) => {
    try {
      let headers = {}
      if(isMultipart){
        headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      }

      const response = await apiClient.post(`/${resource}/`, params, headers);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  nuevoResource: async (resource) => {
    try {
      const response = await apiClient.post(`/${resource}/new`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  eliminarResource: async (resource, id) => {
    try {
      const response = await apiClient.delete(`/${resource}/${id}`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  obtenerStockPorProductoId: async (id) => {
    try {
      const response = await apiClient.get(`/productos/${id}/stockPorLocal`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default resourcesServices;
