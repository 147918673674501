import React, { useEffect, useState, useRef } from 'react';
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from "@mediapipe/tasks-vision";
import { Color, Euler, Matrix4 } from 'three';
import { Canvas, useFrame, useGraph } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import BotonVolver from '../../components/BotonVolver';

let video;
let faceLandmarker;
let lastVideoTime = -1;
let headMesh;
let rotation;
let blendshapes = [];

const Avatar3D = () => {
  useEffect(() => {
    setup()
  }, []);

  const setup = async () => {
    const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");

    faceLandmarker = await FaceLandmarker.createFromOptions(
      vision,
      {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          delegate: "CPU"
        },
        outputFaceBlendshapes: true,
        outputFacialTransformationMatrixes: true,
        runningMode: 'VIDEO'
      });

    video = document.getElementById('video');
    navigator.mediaDevices.getUserMedia({
      audio:false,
      video: {width: 860, height: 640}
    }).then((stream) => {
      video.srcObject = stream;
      video.addEventListener('loadeddata', predict);
    })
  }

  const predict = () => {

    let nowInMs = Date.now();
    if (lastVideoTime !== video.currentTime) {
      lastVideoTime = video.currentTime;
      const result = faceLandmarker.detectForVideo(video, nowInMs)
      // console.log(result);

      if(result.facialTransformationMatrixes && result.facialTransformationMatrixes.length > 0 &&
        result.faceBlendshapes && result.faceBlendshapes.length > 0 ) {
        const matrix = new Matrix4().fromArray(result.facialTransformationMatrixes[0].data);
        rotation = new Euler().setFromRotationMatrix(matrix);

        blendshapes = result.faceBlendshapes[0].categories
      }
    }

    requestAnimationFrame(predict);
  }

  return (
    <div className='text-start image-bg'>
      <div className='d-none'>
        <video autoPlay id='video'></video>
      </div>
      <Canvas
        className='vh-100'
        camera={{
          fov: 25
        }}
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[1,1,1]} color={new Color(1,0,0)} intensity={0.5} />
        <pointLight position={[-1,0,1]} color={new Color(0,1,0)} intensity={0.5} />
        <Avatar />
      </Canvas>
    </div>
  );
};

const Avatar = () => {
  const avatar = useGLTF("https://models.readyplayer.me/664288599683e033efdf8c7b.glb?morphTargets=ARKit&textureAtlas=1024")
  const {nodes} = useGraph(avatar.scene)

  // console.log(nodes)
  console.log(rotation)
  useEffect(() => {
    headMesh = nodes.Wolf3D_Avatar
  },[nodes])

  useFrame((_, delta) => {
    // console.log(rotation)
    if(headMesh !== null) {
      blendshapes.forEach((blendshape) => {
        let index = headMesh.morphTargetDictionary[blendshape.categoryName]
        if(index => 0){
          headMesh.morphTargetInfluences[index] = blendshape.score;
        }
      })
    }

    if(rotation){
      nodes.Head.rotation.set(rotation.x / 3, rotation.y / 3, rotation.z / 3);
      nodes.Neck.rotation.set(rotation.x / 3, rotation.y / 3, rotation.z / 3);
      nodes.Spine1.rotation.set(rotation.x / 3, rotation.y / 3, rotation.z / 3);
    }
  })

  return <primitive object={avatar.scene} position={[0,-1.65,4]} />;
}

export default Avatar3D;