import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';

const ListaMarcas = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [marcas, setMarcas] = useState([]);
  // const [renderHTML, setRenderHTML] = useState(false);

  useEffect(() => {
    obtenerMarcas()
  }, []);

  // useEffect(() => {
  //   if(authInformation.autenticado && ((authInformation.rol === "Organizador" || authInformation.rol === "Admin"))){
  //     setRenderHTML(true)
  //   }
  // }, [authInformation]);

  const obtenerMarcas = async (otro = '&estado=') => {
    try {
      const data = await resourcesServices.obtenerResources('marcas', otro);
      setMarcas(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };



  const eliminarMarca = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar la marca ?')){
        return;
      }

      await resourcesServices.eliminarResource('marcas', id);
      const copiaMarcas = [...marcas];
      setMarcas(copiaMarcas.filter((marcas) => marcas.id !== id))

      // obtenerUsuarios()
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("\n"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div>
      <h3>Marcas</h3>
      {
        (authInformation.rol === "Admin") && (
          <>
          <div className='mt-3'>
            <Link className="btn btn-primary" to={`/cl/${marcaActual.url_name}/marca`}>Nueva Marca</Link>
          </div>

          {/* <div className="btn-group ms-3" role="group" aria-label="Basic example">
            <button onClick={()=>obtenerUsuarios('&estado=')} className='btn btn-outline-secondary'>Todos</button>
            <button onClick={()=>obtenerUsuarios('&estado=activo')} className='btn btn-outline-secondary'>Activos</button>
            <button onClick={()=>obtenerUsuarios('&estado=bloqueado')} className='btn btn-outline-secondary'>Bloqueados</button>
          </div> */}
            <ul className="list-group list-group-flush mt-3">
              {
                marcas.map((marca, index) => (
                  <li key={index} className="list-group-item text-start">
                    <span className={`badge text-bg-primary`}>{marcas.length - index}</span>
                    <span className={`ms-1 badge text-bg-secondary`}>{marca.estado}</span>
                    <span className='ms-1'><b>{marca && marca.nombre}</b></span>

                    <div className="btn-group ms-3" role="group" aria-label="Basic example">
                      {/* {
                        usuario.estado === 'activo' ? (
                          <button onClick={()=>estadoUsuario(usuario.id, "bloqueado")} className='btn btn-warning'>Bloquear</button>
                        ) : (
                          <button onClick={()=>estadoUsuario(usuario.id, "activo")} className='btn btn-success'>Activar</button>
                        )
                      } */}
                      <Link className="btn btn-success" to={`/cl/${marcaActual.url_name}/marca/${marca.id}`}>Editar</Link>
                      <button onClick={()=>eliminarMarca(marca.id)} className='btn btn-danger'>Eliminar</button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </>
        )
      }

    </div>
  );
};

export default ListaMarcas;