import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import TextControl from './customizedInputs/TextControl';
import TextAreaControl from './customizedInputs/TextAreaControl';
import SelectControl from './customizedInputs/SelectControl';
import { InputContext } from '../contexts/InputContext';
import MenuPagos from './MenuPagos';

const MedioDePagoForm = ({marcaActual}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)
  const [esNuevo, setEsNuevo] = useState(true);
  const [medioDePago, setMedioDePago] = useState({});
  const [estados, setEstados] = useState([]);

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(id !== undefined){
      obtenerMedioDePago(id)
    } else {
      nuevoMedioDePago()
    }
  }, [id]);


  const obtenerMedioDePago = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('medios_de_pagos', id);
      setMedioDePago(data);

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        console.log('error', error.response.data.error)
        // navigate(`/`);
      }
    }
  };

  const nuevoMedioDePago = async () => {
    try {
      const data = await resourcesServices.nuevoResource('medios_de_pagos');
      setMedioDePago(data);
    } catch (error) {
      console.log('error', error)
    }
  };


  const guardarMedioDePago = async () => {
    try {

      let data;
      if(esNuevo){
        data = await resourcesServices.crearResource('medios_de_pagos', {medios_de_pago: medioDePago}, true);
        navigate(`/cl/${marcaActual.url_name}/medio-de-pago/${data.id}`);
      } else {
        data = await resourcesServices.actualizarResource('medios_de_pagos', id, {medios_de_pago: medioDePago}, true);
        setMedioDePago(data);
      }

      alert("Datos actualizados")


    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setMedioDePago({...medioDePago,
        [name]: value
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    guardarMedioDePago()
  }


  return (
    <div>
        <main className="w-100 m-auto" style={{maxWidth: '660px'}}>
          <h5>Medio de Pago</h5>
          <MenuPagos marcaActual={marcaActual} />

          <form className='text-start'>
            <TextControl
                value={medioDePago.nombre}
                name={'nombre'}
                handleInput={handleInput}
              />
            <TextAreaControl
                value={medioDePago.descripcion}
                name={'descripcion'}
                height={'150px'}
                handleInput={handleInput}
              />

            <TextControl
                value={medioDePago.recargo}
                name={'recargo'}
                handleInput={handleInput}
                type={'number'}
              />

            <div className="">
              <button className="btn btn-primary mt-3" onClick={handleFormSubmit} type="submit">Guardar</button>
            </div>

          </form>

          {/* <pre>{JSON.stringify(usuariosSeleccionados, null, 2)}</pre> */}
        </main>
    </div>
  );
};

export default MedioDePagoForm;