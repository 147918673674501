import React, {useEffect} from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';

const RedirectFromShort = () => {
  let { shorted_url } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if(shorted_url){
      obtenerRealURL()
    } else {
      navigate(`/`);
    }

  }, [shorted_url]);


  const obtenerRealURL = async () => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('shortened_urls', shorted_url);
      console.log(data.data.original_url);
      window.location.replace(data.data.original_url);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div>
      Redireccionando ...
    </div>
  );
};

export default RedirectFromShort;