import React from 'react';
import { Link } from "react-router-dom";

const AccesoTexto = () => {
  return (
    <div>
      <h1>Acceso</h1>

      <div className="mt-3">
        Ahora podés compartir imágenes desde aquí mismo. Clickeá el botón que está debajo y compartí una foto que saldrá en la pantalla.
      </div>


      <div className="mt-3">
        <Link className='btn btn-green' to={`/compartir-foto`}>
          <b>Quiero</b> Compartir una foto
        </Link>
      </div>

      {/* <div className="mt-3">
        Si todavía no tenés tu acceso, podés tenerlo clickeando el botón que dice: "Quiero mí acceso" <br />
        Al hacerlo se va a abrir tu Whatsapp. Simplemente enviás el mensaje que aparece.
      </div>


      <div className="mt-3">
        <Link className='btn btn-green' to={`https://wa.me/5492995368458?text=Quiero mí acceso`}>
          <b>Quiero</b> mí Acceso
        </Link>
      </div>

      <div className="container-fluid mt-3 text-start">
          Luego de enviar el mensaje vas a poder interactuar de dos formas:
          <ol className="list-group list-group-numbered">
            <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold">Compartiendo textos e imágenes</div>
                Desde tu Whatsapp ahora vas a poder compartir imágenes y textos. Enviálo como si se lo enviaras a un amigo. Todo lo que envíes va a estar sujeto a revisión antes de publicarlo en pantalla.
              </div>
            </li>
            <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold">Acceso a la aplicación</div>
                En tu Whatsapp recibiste un enlace. Al clickearlo vas a acceder a la aplicación desde donde vas a poder ver los próximos eventos, participar de sorteos y próximamente muchas mas novedades
              </div>
            </li>
          </ol>
      </div> */}
    </div>
  );
};

export default AccesoTexto;