import * as THREE from 'three'
import { useState, useRef, Suspense, useMemo, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import { Canvas, useThree, useFrame, useLoader, useGraph } from '@react-three/fiber'
import { Reflector, CameraShake, OrbitControls, useTexture, useGLTF, Cylinder } from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from "@mediapipe/tasks-vision";
import { Color, Euler, Matrix4 } from 'three';
import { InputContext } from '../../contexts/InputContext';
import Imagenes from './Imagenes'
import Votacion from './Votacion';

let video;
let faceLandmarker;
let lastVideoTime = -1;
let headMesh;
let rotation;
let blendshapes = [];

const Mixing3D = () => {
  let { tipo } = useParams();

  const [renderHTML, setRenderHTML] = useState(false);
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)

  const [isTest, setIsTest] = useState(false);
  const [hasImages, setHasImages] = useState(false);
  const [hasVotacion, setHasVotacion] = useState(false);

  useEffect(() => {
    checkSignIn()
  }, []);

  useEffect(() => {
    if(authInformation.autenticado === true && authInformation.rol === 'Admin'){
      setRenderHTML(true);
      setup()
    }
  }, [authInformation]);

  useEffect(() => {
    if(tipo !== undefined && tipo.length > 0) {
      const tipos = tipo.split("-")

      setHasImages(tipos.includes("imagenes"))
      setHasVotacion(tipos.includes("votacion"))
      setIsTest(tipos.includes("test"))
    }

  }, [tipo]);

  const setup = async () => {
    const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");

    faceLandmarker = await FaceLandmarker.createFromOptions(
      vision,
      {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          delegate: "CPU"
        },
        outputFaceBlendshapes: true,
        outputFacialTransformationMatrixes: true,
        runningMode: 'VIDEO'
      });

    video = document.getElementById('video');
    navigator.mediaDevices.getUserMedia({
      audio:false,
      video: {width: 860, height: 640}
    }).then((stream) => {
      video.srcObject = stream;
      video.addEventListener('loadeddata', predict);
    })
  }

  const predict = () => {

    let nowInMs = Date.now();
    if (lastVideoTime !== video.currentTime) {
      lastVideoTime = video.currentTime;
      const result = faceLandmarker.detectForVideo(video, nowInMs)
      // console.log(result);

      if(result.facialTransformationMatrixes && result.facialTransformationMatrixes.length > 0 &&
        result.faceBlendshapes && result.faceBlendshapes.length > 0 ) {
        const matrix = new Matrix4().fromArray(result.facialTransformationMatrixes[0].data);
        rotation = new Euler().setFromRotationMatrix(matrix);

        blendshapes = result.faceBlendshapes[0].categories
      }
    }

    requestAnimationFrame(predict);
  }

  return (
    <>
    {
      renderHTML && (
        <>
        <div className='d-none'>
          <video autoPlay id='video'></video>
        </div>

        { hasImages  && (<Imagenes isTest={isTest} />) }
        { hasVotacion  && (<Votacion isTest={isTest} />) }

        <Canvas className='vh-100' dpr={[1, 1.5]} camera={{ position: [0, 0, 3.5] }}>
        <color attach="background" args={['black']} />
        <ambientLight />
        <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} />
        <Suspense fallback={null}>
          <Rig>
            <Logo scale={3} position={[0, 1, 0]} />
            {/* <LogoPatagonia scale={0.5} rotation={[0,Math.PI/-8,0]} position={[2, -0.5, 1.2]} /> */}
            {/* <Triangle color="#ff2060" scale={0.009} position={[-3, 0.5, -7]} rotation={[0, 0, Math.PI / 3]} /> */}
            <Triangle color="white" scale={0.009} position={[9, 0.5, -7]} rotation={[0, 0, Math.PI / 3]} />
            {/* <Triangle color="cyan" scale={0.009} position={[4, 0, -2]} rotation={[0, 0, Math.PI / 3]} /> */}
            <Triangle color="orange" scale={0.009} position={[-5, 0, -4]} rotation={[0, 0, Math.PI / 3]} />
            {/* <Triangle color="orange" scale={0.009} position={[5, 0, 0]} rotation={[0, 0, Math.PI / 3]} /> */}
            <Triangle color="orange" scale={0.009} position={[6, 0, -2]} rotation={[0, 0, Math.PI / 3]} />
            <Triangle color="white" scale={0.009} position={[-16, 2, -10]} rotation={[0, 0, Math.PI / 3]} />
            <Ground mirror={1} blur={[500, 100]} mixBlur={12} mixStrength={1.5} rotation={[-Math.PI / 2, 0, Math.PI / 2]} position-y={-0.8} />
          </Rig>
          <EffectComposer multisampling={8}>
            <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
            <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
          </EffectComposer>
        </Suspense>
        <CameraShake yawFrequency={0.3} pitchFrequency={0.3} rollFrequency={0.4} intensity={1} />
      </Canvas>
      <Canvas
        orthographic
        camera={{
          position: [0, 0, 10],
          zoom: 100,
          left: -window.innerWidth / 2,
          right: window.innerWidth / 2,
          top: window.innerHeight / 2,
          bottom: -window.innerHeight / 2,
          near: 0.1,
          far: 1000,
        }}
        style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
      >
        <ambientLight />
        <Avatar scale={9} position={[-4,-15,4]} />
      </Canvas>
      </>
      )
    }
    </>

  );
};

const Logo = ({ color, ...props }) => {
  const texture = useLoader(THREE.TextureLoader, '/threejs/logo.png')
  console.log(texture)
  return (
    <mesh {...props}>
      <planeGeometry attach="geometry" />
      <meshBasicMaterial transparent={true} attach="material" map={texture} />
    </mesh>
  )
}

const LogoPatagonia = ({ ...props }) => {
  const texture = useLoader(THREE.TextureLoader, '/threejs/patagonia-interactiva.png')
  const ratio = 1994/528;
  return (
    <mesh {...props}>
      {/* <meshBasicMaterial attach="material" /> */}
      <planeGeometry args={[1*ratio,1]} attach="geometry" />
      {/* <Plane args={[2, 2]} /> */}
      <meshBasicMaterial transparent={true} attach="material" map={texture} />
    </mesh>
  )
}

const Triangle = ({ color, ...props }) => {
  const ref = useRef()
  const [r] = useState(() => Math.random() * 10000)
  useFrame((_) => (ref.current.position.y = -1.75 + Math.sin(_.clock.elapsedTime + r) / 10))
  const { paths: [path] } = useLoader(SVGLoader, '/threejs/triangle.svg') // prettier-ignore
  const geom = useMemo(() => SVGLoader.pointsToStroke(path.subPaths[0].getPoints(), path.userData.style), [])
  return (
    <group ref={ref}>
      <mesh geometry={geom} {...props}>
        <meshBasicMaterial color={color} toneMapped={false} />
      </mesh>
    </group>
  )
}

const Rig = ({children}) => {
  const ref = useRef()
  const vec = new THREE.Vector3()
  const { camera, mouse } = useThree()
  useFrame(() => {
    // camera.position.lerp(vec.set(mouse.x * 2, 0, 3.5), 0.05)
    camera.position.lerp(vec.set(0, 0, 3.5), 0.05)
    // ref.current.position.lerp(vec.set(mouse.x * 1, mouse.y * 0.1, 0), 0.1)
    // ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (-mouse.x * Math.PI) / 20, 0.1)
  })
  return <group ref={ref}>{children}</group>
}


const Ground = (props) => {
  const [floor, normal] = useTexture(['/threejs/SurfaceImperfections003_1K_var1.jpg', '/threejs/SurfaceImperfections003_1K_Normal.jpg'])
  return (
    <Reflector resolution={1024} args={[8, 8]} {...props}>
      {(Material, props) => <Material color="#f0f0f0" metalness={0} roughnessMap={floor} normalMap={normal} normalScale={[2, 2]} {...props} />}
    </Reflector>
  )
}

const Avatar = (props) => {
  // const avatar = useGLTF("https://models.readyplayer.me/664288599683e033efdf8c7b.glb?morphTargets=ARKit&textureAtlas=1024")
  const avatar = useGLTF("https://models.readyplayer.me/664d53af43dbd726ee19620e.glb?morphTargets=ARKit&textureAtlas=1024")
  const {nodes} = useGraph(avatar.scene)

  useEffect(() => {
    headMesh = nodes.Wolf3D_Avatar
  },[nodes])

  useFrame((_, delta) => {
    if(headMesh !== null) {
      blendshapes.forEach((blendshape) => {
        let index = headMesh.morphTargetDictionary[blendshape.categoryName]
        if(index => 0){
          headMesh.morphTargetInfluences[index] = blendshape.score;
        }
      })
    }

    if(rotation){
      nodes.Head.rotation.set(rotation.x / 3, rotation.y / 3, rotation.z / 3);
      nodes.Neck.rotation.set(rotation.x / 3, rotation.y / 3, rotation.z / 3);
      nodes.Spine1.rotation.set(rotation.x / 3, rotation.y / 3, rotation.z / 3);
    }
  })

  return <primitive object={avatar.scene} {...props} />;
}


export default Mixing3D;
