import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';

const ListaMensajes = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [mensajes, setMensajes] = useState([]);
  const [header, setHeader] = useState([]);
  // const [renderHTML, setRenderHTML] = useState(false);

  useEffect(() => {
    obtenerMensajeria('', '', 1, 'tipo=directo')
  }, []);

  // useEffect(() => {
  //   if(authInformation.autenticado && ((authInformation.rol === "Organizador" || authInformation.rol === "Admin"))){
  //     setRenderHTML(true)
  //   }
  // }, [authInformation]);

  const obtenerMensajeria = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('mensajes', sortBy, q, page, otro);
      setMensajes([...mensajes, ...data.data]);
      setHeader(data.headers);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };



  const eliminarMensajeria = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar el mensaje ?')){
        return;
      }

      await resourcesServices.eliminarResource('mensajes', id);
      const copiaMensajes = [...mensajes];
      setMensajes(copiaMensajes.filter((mensajes) => mensajes.id !== id))


      // obtenerUsuarios()
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("\n"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div>
      <h3>Lista de mensajes directos a Whatsapp</h3>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <>
          <div className='mt-3'>
            <Link className="btn btn-primary" to={`/cl/${marcaActual.url_name}/mensaje/`}>Nueva mensajería</Link>
            <BotonVolver />
          </div>

          {/* <div className="btn-group ms-3" role="group" aria-label="Basic example">
            <button onClick={()=>obtenerUsuarios('&estado=')} className='btn btn-outline-secondary'>Todos</button>
            <button onClick={()=>obtenerUsuarios('&estado=activo')} className='btn btn-outline-secondary'>Activos</button>
            <button onClick={()=>obtenerUsuarios('&estado=bloqueado')} className='btn btn-outline-secondary'>Bloqueados</button>
          </div> */}
            <ul className="list-group list-group-flush mt-3">
              {
                mensajes.map((mensaje, index) => (
                  <li key={index} className="list-group-item text-start">
                    <span className={`badge text-bg-primary`}>{mensajes.length - index}</span>
                    <span className='ms-1'><b>{mensaje && mensaje.titulo}</b></span>

                    <div className="btn-group ms-3" role="group" aria-label="Basic example">
                      {/* {
                        usuario.estado === 'activo' ? (
                          <button onClick={()=>estadoUsuario(usuario.id, "bloqueado")} className='btn btn-warning'>Bloquear</button>
                        ) : (
                          <button onClick={()=>estadoUsuario(usuario.id, "activo")} className='btn btn-success'>Activar</button>
                        )
                      } */}
                      <Link className="btn btn-success" to={`/cl/${marcaActual.url_name}/mensaje/${mensaje.id}`}>Editar</Link>
                      <button onClick={()=>eliminarMensajeria(mensaje.id)} className='btn btn-danger'>Eliminar</button>
                    </div>
                  </li>
                ))
              }
            </ul>

            {
              header["total-pages"] !== header["current-page"] && (
                <button onClick={()=>obtenerMensajeria('', '', header["current-page"] + 1, 'tipo=directo')} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
              )
            }
          </>
        )
      }

    </div>
  );
};

export default ListaMensajes;