import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import TextControl from './customizedInputs/TextControl';
import TextAreaControl from './customizedInputs/TextAreaControl';
import SelectControl from './customizedInputs/SelectControl';
import { InputContext } from '../contexts/InputContext';
import MenuPagos from './MenuPagos';

const PagoForm = ({marcaActual}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)
  const [esNuevo, setEsNuevo] = useState(true);
  const [pago, setPago] = useState({});
  const [estados, setEstados] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [eventosSeleccionados, setEventosSeleccionados] = useState([]);
  const [eventosNoSeleccionados, setEventosNoSeleccionados] = useState([]);
  const [mediosDePagos, setMediosDePagos] = useState([]);
  const [mediosDePagosSeleccionados, setMediosDePagosSeleccionados] = useState([]);
  const [mediosDePagosNoSeleccionados, setMediosDePagosNoSeleccionados] = useState([]);
  const [header, setHeader] = useState([]);
  const [cuotasPago, setCuotasPago] = useState([]);

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(id !== undefined){
      obtenerPago(id)
    } else {
      nuevoPago()
      setEventosSeleccionados([])
      setMediosDePagosSeleccionados([])
    }

    obtenerEventos('', '', 1, '')
    obtenerMediosDePagos()
  }, [id]);


  useEffect(() => {
    setEventosNoSeleccionados( [...eventos].filter(e => !eventosSeleccionados.map(es => es.id).includes(e.id)) )

  }, [eventos, eventosSeleccionados]);

  useEffect(() => {
    setMediosDePagosNoSeleccionados( [...mediosDePagos].filter(e => !mediosDePagosSeleccionados.map(mps => mps.id).includes(e.id)) )

  }, [mediosDePagos, mediosDePagosSeleccionados]);


  const obtenerPago = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('pagos', id);
      setPago(data);
      setEventosSeleccionados(data.eventos)
      setMediosDePagosSeleccionados(data.medios_de_pagos)

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        console.log('error', error.response.data.error)
        // navigate(`/`);
      }
    }
  };

  const obtenerEventos = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('eventos', sortBy, q, page, otro);
      if (page === 1) {
        setEventos(data.data);
      } else {
        setEventos([...eventos, ...data.data]);
      }

      setHeader(data.headers);

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const obtenerMediosDePagos = async (otro = '&estado=') => {
    try {
      const data = await resourcesServices.obtenerResources('medios_de_pagos', otro);
      setMediosDePagos(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };


  const nuevoPago = async () => {
    try {
      const data = await resourcesServices.nuevoResource('pagos');
      setPago(data);
    } catch (error) {
      console.log('error', error)
    }
  };

  const nuevaCuotaPago = () => {
    const copiaPago = {...pago}

    copiaPago.cuotas_pagos.push({nombre: "", monto: 0, dias_vencimiento: 0})

    setPago({...pago,
      ['cuotas_pagos']: copiaPago.cuotas_pagos
    })

    // setCuotasPago([...cuotasPago, {nombre: "", monto: 0, dias_vencimiento: 0}])
  }


  const guardarPago = async () => {
    try {
      const copiaPago = {...pago}
      copiaPago.evento_ids = eventosSeleccionados.map(evento => evento.id)
      copiaPago.medios_de_pago_ids = mediosDePagosSeleccionados.map(medioDePago => medioDePago.id)

      copiaPago.cuotas_pagos_attributes = copiaPago.cuotas_pagos
      delete copiaPago.cuotas_pagos

      let data;
      if(esNuevo){
        data = await resourcesServices.crearResource('pagos', {pago: copiaPago});
        navigate(`/cl/${marcaActual.url_name}/pago/${data.id}`);
      } else {
        data = await resourcesServices.actualizarResource('pagos', id, {pago: copiaPago});
        setPago(data);
      }

      alert("Datos actualizados")


    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("\n"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };

  const eliminarCuota = async (cuota) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar la cuota ?')){
        return;
      }

      const params = {cuotas_pagos_attributes: [{id: cuota.id, "_destroy": 1} ]}

      const data = await resourcesServices.actualizarResource('pagos', id, {pago: params});
      setPago(data);

      alert("Cuota eliminada")


    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("\n"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPago({...pago,
        [name]: value
    })
  }

  const handleInputNested = (e, index) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split('.');

    const copiaPago = {...pago}

    copiaPago.cuotas_pagos[index][childKey] =  value

    setPago({...pago,
      ['cuotas_pagos']: copiaPago.cuotas_pagos
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    guardarPago()
  }

  const moveEvent = (e, evento) => {
    e.preventDefault()

    setEventosSeleccionados([...eventosSeleccionados, evento])
  }

  const removeEvent = (e, evento) => {
    e.preventDefault()
    setEventosSeleccionados([...eventosSeleccionados].filter(e => e.id !== evento.id))
  }

  const moveMedioDePago = (e, medioDePago) => {
    e.preventDefault()

    // setMediosDePagos([...mediosDePagos].filter(e => e.id !== medioDePago.id))
    setMediosDePagosSeleccionados([...mediosDePagosSeleccionados, medioDePago])
  }

  const removeMedioDePago = (e, medioDePago) => {
    e.preventDefault()
    // setMediosDePagos([...mediosDePagos, medioDePago])
    setMediosDePagosSeleccionados([...mediosDePagosSeleccionados].filter(e => e.id !== medioDePago.id))
  }

  const SlideNextButton = ({name}) => {
    return (
      <button className="btn btn-warning" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        {name || 'Siguiente'}
      </button>
    )
  }

  const SlidePrevButton = () => {
    return (
      <button className="btn btn-warning" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        Anterior
      </button>
    )
  }

  return (
    <div>
        <main className="w-100 m-auto text-start" style={{maxWidth: '660px'}}>
          <MenuPagos marcaActual={marcaActual} />

          <div className="h3 pb-2 mb-4 text-warning border-bottom border-warning">Pago</div>

          <form className='text-start'>
            <div id="carouselExample" className="carousel slide">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <h5>Paso 1</h5>
                  <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Nombres de ejemplos:</strong> Evento completo. Pago nivel 1. Pago por 3 Niveles. Pase gratis.
                  </div>

                  <TextControl
                    value={pago.nombre}
                    name={'nombre'}
                    handleInput={handleInput}
                  />
                  <TextAreaControl
                      value={pago.descripcion}
                      name={'descripcion'}
                      height={'150px'}
                      handleInput={handleInput}
                    />

                  <TextControl
                      value={pago.fecha_vencimiento}
                      name={'fecha_vencimiento'}
                      handleInput={handleInput}
                      type={'datetime-local'}
                    />

                  <div className='text-center border-top border-warning p-2'>
                    <div className='btn-group' role="group">
                      <SlideNextButton />
                    </div>
                  </div>

                </div>
                <div className="carousel-item">

                  <h5>Paso 2</h5>
                  <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    Son los <strong>eventos</strong> en donde se aplicará el tipo de pago. Seleccionelos
                  </div>

                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5>Disponibles</h5>
                      <div className="list-group">
                        {
                          eventosNoSeleccionados && eventosNoSeleccionados.map((evento, index) => (
                            <a key={index} href="#" onClick={(e) => moveEvent(e, evento)} className="list-group-item list-group-item-action">{evento.nombre} | {evento.estado}</a>
                          ))
                        }
                      </div>
                      {
                        header["total-pages"] !== header["current-page"] && (
                          <button type='button' onClick={()=>obtenerEventos('', '', header["current-page"] + 1, '')} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
                        )
                      }
                    </div>
                    <div className='col-6'>
                      <h5>Seleccionados</h5>
                      <div className="list-group">
                        {
                          eventosSeleccionados && eventosSeleccionados.map((evento, index) => (
                            <a key={index} href="#" onClick={(e) => removeEvent(e, evento)} className="list-group-item list-group-item-action">{evento.nombre} | {evento.estado}</a>
                          ))
                        }
                      </div>
                    </div>
                  </div>

                  <div className='text-center'>
                    <div className='btn-group' role="group">
                      <SlidePrevButton />
                      <SlideNextButton />
                    </div>
                  </div>

                </div>
                <div className="carousel-item">

                  <h5>Paso 3</h5>
                  <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    Elija las <strong>formas de pago</strong> disponibles. Seleccionelos
                  </div>

                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5>Disponibles</h5>
                      <div className="list-group">
                        {
                          mediosDePagosNoSeleccionados && mediosDePagosNoSeleccionados.map((medioDePago, index) => (
                            <a key={index} href="#" onClick={(e) => moveMedioDePago(e, medioDePago)} className="list-group-item list-group-item-action">{medioDePago.nombre} | recargo: {medioDePago.recargo}%</a>
                          ))
                        }
                      </div>
                    </div>
                    <div className='col-6'>
                      <h5>Seleccionados</h5>
                      <div className="list-group">
                        {
                          mediosDePagosSeleccionados && mediosDePagosSeleccionados.map((medioDePago, index) => (
                            <a key={index} href="#" onClick={(e) => removeMedioDePago(e, medioDePago)} className="list-group-item list-group-item-action">{medioDePago.nombre} | recargo: {medioDePago.recargo}%</a>
                          ))
                        }
                      </div>
                    </div>
                  </div>

                  <div className='text-center'>
                    <div className='btn-group' role="group">
                      <SlidePrevButton />
                      <SlideNextButton />
                    </div>
                  </div>

                </div>
                <div className="carousel-item">

                  <h5>Paso 4</h5>
                  <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    Acá se define la cantidad de <strong>cuotas</strong> con las que puede pagarse el evento. Si es un pago único, puede elegir como nombre "Pago Único".
                  </div>

                  <div className='mb-3'>
                    <div className="row">
                      {
                        pago && pago.cuotas_pagos && pago.cuotas_pagos.map((cuota, index) => (
                          <div key={index} className='col-6 mt-3'>
                            <TextControl
                                value={cuota.id}
                                name={'cuotas_pagos.id'}
                                handleInput={(e)=>handleInputNested(e, index)}
                                type='hidden'
                              />
                            <TextControl
                                value={cuota.nombre}
                                name={'cuotas_pagos.nombre'}
                                handleInput={(e)=>handleInputNested(e, index)}
                              />
                            <TextControl
                                value={cuota.monto}
                                name={'cuotas_pagos.monto'}
                                handleInput={(e)=>handleInputNested(e, index)}
                                type='number'
                              />
                            <TextControl
                                value={cuota.dias_vencimiento}
                                name={'cuotas_pagos.dias_vencimiento'}
                                handleInput={(e)=>handleInputNested(e, index)}
                                type='number'
                              />
                              <div className="d-grid gap-2">
                                <button disabled={(cuota.id && cuota.id>0) ? '' : 'disabled'} className="btn btn-danger" onClick={() => eliminarCuota(cuota)} type="button">Eliminar Cuota</button>
                              </div>
                          </div>
                        ))
                      }
                    </div>
                    <button className="btn btn-primary mt-3" onClick={nuevaCuotaPago} type="button">Agregar Cuota</button>
                  </div>

                  <div className='text-center'>
                    <div className='btn-group' role="group">
                      <SlidePrevButton />
                      <button className="btn btn-primary" onClick={handleFormSubmit} type="submit">Guardar</button>
                      <SlideNextButton name={'Principio'} />
                    </div>
                  </div>

                </div>
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>










          </form>

          {/* <pre>{JSON.stringify(usuariosSeleccionados, null, 2)}</pre> */}
        </main>
    </div>
  );
};

export default PagoForm;