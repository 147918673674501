import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { InputContext } from '../contexts/InputContext';
import resourcesServices from '../services/resourcesServices';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';

const CompartidoEventos = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)
  const [multimedias, setMultimedias] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchCompartidoUsuario()
  }, [id]);

  useEffect(() => {
    // if( authInformation.autenticado === true && authInformation.rol === 'Admin' ){
    // }
  }, [authInformation]);

  const fetchCompartidoUsuario = async () => {
    try {
      const data = await resourcesServices.obtenerResources('uuebs/multimediaUsuario');
      setMultimedias(data.data);
    } catch (error) {
      errorMessage(error)
    }
  };


  return (
    <div>
      <h3 className='mt-3'>Lo que compartí <BotonVolver /></h3>
      <div class="fw-lighter">
        Las imágenes corresponden a las aprobadas. Las imágenes que no se aprobaron no son mostradas
      </div>
      <div className='row'>
        {
          multimedias && multimedias.map(mm => (
            (mm.storyImageUrl || mm.imageUrl) && (
              <div key={mm.id} className='col-sm-12 col-md-4 mt-3 border-top border-3 p-3'>
              {
                mm.evento && mm.evento.id && (
                  <>
                  <h6>{mm.evento.nombre}</h6>
                  <div class="fw-lighter">
                  {mm.evento.fecha_inicio_formated}
                  </div>
                  </>
                )
              }
              <img src={mm.storyImageUrl || mm.imageUrl} className="img-fluid" alt='' />
              </div>
            )
          ))
        }
      </div>
    </div>
  );
};

export default CompartidoEventos;