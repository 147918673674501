import React, { useContext, useState, useEffect } from 'react';
import { InputContext } from '../contexts/InputContext';
import { useNavigate, useParams } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const [authInformation, setAuthInformation] = useContext(InputContext)

  useEffect(() => {
    localStorage.removeItem('token');
    setAuthInformation({token: undefined, rol: undefined, autenticado: false})
    navigate(`/`);
  }, []);

  return (
    <div className="container-fluid">
      Saliendo ...
    </div>
  );
};

export default Logout;