import React from 'react';
import { Link } from "react-router-dom";

const Consultas = () => {
  return (
    <div className='text-start pb-5'>
      <h1 id="consultas">Consultas</h1>
      Podés solicitar más información al siguiente teléfono: +54 9 (299)6740990<br />

      <div className="text-center d-flex my-3">
        También por &nbsp; <Link className='link-warning link-offset-2 link-underline link-underline-opacity-25' target="_blank" to={`https://wa.me/5492996740990?text=Quiero información sobre Kizomba Patagonia 2024`}>Whatsapp</Link>
      </div>

      <div className="text-center d-flex my-3">
        Instagram del evento &nbsp; <Link className='link-warning link-offset-2 link-underline link-underline-opacity-25' target="_blank" to={`https://www.instagram.com/kizomba.patagonia/`}>Kizomba Patagonia</Link>
      </div>

      <div className="text-center d-flex my-3">
        Colaboradores &nbsp; <Link className='link-warning link-offset-2 link-underline link-underline-opacity-25' target="_blank" to={`https://www.instagram.com/platanofest/`}>Platano Fest</Link>
      </div>
    </div>
  );
};

export default Consultas;