import React from 'react';
import { Link } from "react-router-dom";
import logoKizombaPatagonia from '../assets/images/logo-kizomba-patagonia.png';
import Presentacion from '../components/kp2024/Presentacion';
import Fechas from '../components/kp2024/Fechas';
import Instructoras from '../components/kp2024/Instructoras';
import Precios from '../components/kp2024/Precios';
import Consultas from '../components/kp2024/Consultas';
import MedioDePago from '../components/kp2024/MedioDePago';

const KizombaPatagonia = () => {
  return (
    <div className='kizomba-patagonia'>
      <div className='container'>
        <div className='text-center'>
          <img src={logoKizombaPatagonia} className="img-fluid mt-3 mb-3" alt="logo Kizomba Patagonia" />
        </div>

        <nav className="navbar bg-kizomba navbar-expand-lg mt-3 mb-3">
          <div className="container-fluid">
            <a className="navbar-brand" href="#inicio">KP2024</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="#inicio">Presentación</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#fechas">Ritmos</a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="#fechas">Fechas y Ritmos</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#instructoras">Instructoras</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#precios">Precios</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#mediopago">Formas y Medios de Pago</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#consultas">Consultas</a>
                </li>
                <li className="nav-item">
                  <Link className='nav-link' to={`/`}>Salir</Link>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link disabled" aria-disabled="true">Disabled</a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>

        <Presentacion />
        <hr />
        <Fechas />
        <hr />
        <Instructoras />
        <hr />
        <Precios />
        <hr />
        <MedioDePago />
        <hr />
        <Consultas />

      </div>
    </div>
  );
};

export default KizombaPatagonia;