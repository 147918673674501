import React, {useState, useEffect} from 'react';

const CLIENT_ID = '655f073044fb4b7eb1d32b680016c7b6'
const CLIENT_SECRET = 'ee2be404353a4785b87445a1d8d3c145'

const Searcher = () => {
  const [searchInput, setSearchInput] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [songs, setSongs] = useState([]);
  const [genrer, setGenrer] = useState('bachata');

  useEffect(() => {
    // API ACCESS TOKEN
    const authParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'grant_type=client_credentials&client_id=' + CLIENT_ID + '&client_secret=' + CLIENT_SECRET
    }

    fetch('https://accounts.spotify.com/api/token', authParams)
      .then(response => response.json())
      .then(data => setAccessToken(data.access_token))

  }, [])



  // useEffect(() => {

  //   const script = document.createElement("script");
  //   script.src = "https://sdk.scdn.co/spotify-player.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   window.onSpotifyWebPlaybackSDKReady = () => {

  //       const player = new window.Spotify.Player({
  //           name: 'Web Playback SDK',
  //           getOAuthToken: cb => { cb(accessToken); },
  //           volume: 0.5
  //       });

  //       setPlayer(player);

  //       player.addListener('ready', ({ device_id }) => {
  //           console.log('Ready with Device ID', device_id);
  //       });

  //       player.addListener('not_ready', ({ device_id }) => {
  //           console.log('Device ID has gone offline', device_id);
  //       });


  //       player.connect();

  //     };
  // }, []);


  const search = async () => {
    console.log("Searching for " + searchInput)

    const searchParams = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      }
    }

    await fetch('https://api.spotify.com/v1/search?q=' + searchInput + '%23'+genrer+'&type=track,artist', searchParams)
      .then(response => response.json())
      .then(data => {
        console.log(data.tracks.items)
        setSongs(data.tracks.items)
      })
  }

  const artists = (arrayArtists) => {
    return arrayArtists.map(artist => artist.name).join(', ')
  }



  return (
    <div className="container-fluid">
      {genrer} | {searchInput}
      <select
        className="form-select mb-3"
        name={'genrer'}
        value={genrer || ''}
        onChange={(e) => setGenrer(e.target.value)}
      >
        <option value={'bachata'}>Bachata</option>
        <option value={'salsa'}>Salsa</option>
        <option value={'kizomba'}>Kizomba</option>

      </select>

      <div className="input-group mb-3">
        <span className="input-group-text">@</span>
        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingInputGroup1"
            placeholder="Buscar tema"
            onKeyDown={event => {
              if (event.key === "Enter") {
                search()
              }
            }}
            onChange={event => setSearchInput(event.target.value)}
          />
          <label htmlFor="floatingInputGroup1">Buscar tema</label>
        </div>
      </div>
      <button type="button" onClick={search} className="btn btn-primary">Buscar</button>

      <div className="container mt-3">
        <div className='row'>
          {
            songs.sort((a, b) => b.popularity - a.popularity).map((song, i) => (
              <div key={i} className='col-7'>
                <div className="card">
                  {/* <img src="..." className="card-img-top" alt="..."> */}
                  <div className="card-header">
                    Popularidad {song.popularity}
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{song.name}</h5>
                    <p className="card-text">{artists(song.artists)}</p>
                    {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                  </div>
                </div>
              </div>
            ))
          }

        </div>
      </div>
    </div>
  );
};

export default Searcher;