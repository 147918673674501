import * as THREE from 'three';
import { useEffect, useState, useRef, Suspense, useMemo, useContext } from 'react'
import { useParams } from "react-router-dom";
import { Canvas, useThree, useFrame, useLoader } from '@react-three/fiber'
import { Reflector, CameraShake, OrbitControls, useTexture, Cylinder } from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import { InputContext } from '../../contexts/InputContext';
import Imagenes from './Imagenes';
import Votacion from './Votacion';


const PublicImages = () => {
  let { tipo } = useParams();
  const [renderHTML, setRenderHTML] = useState(false);

  const [isTest, setIsTest] = useState(false);
  const [hasImages, setHasImages] = useState(false);
  const [hasVotacion, setHasVotacion] = useState(false);

  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)

  useEffect(() => {
    checkSignIn()
  }, []);

  useEffect(() => {
    if(authInformation.autenticado === true && authInformation.rol === 'Admin'){
      setRenderHTML(true);
    }
  }, [authInformation]);


  useEffect(() => {
    if(tipo !== undefined && tipo.length > 0) {
      const tipos = tipo.split("-")

      setHasImages(tipos.includes("imagenes"))
      setHasVotacion(tipos.includes("votacion"))
      setIsTest(tipos.includes("test"))
    }

  }, [tipo]);

  return (
    <>
    {
      renderHTML ? (
        <>
          { hasImages  && (<Imagenes isTest={isTest} />) }
          { hasVotacion  && (<Votacion isTest={isTest} />) }

          <Canvas className='vh-100' dpr={[1, 1.5]} camera={{ position: [0, 0, 3.5] }}>
            <color attach="background" args={['black']} />
            <ambientLight />
            <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} />
            <Suspense fallback={null}>
              <Rig>
                <Logo scale={3} position={[0, 0.9, 0]} />
                {/* <LogoPatagonia scale={0.5} rotation={[0,Math.PI/8,0]} position={[-2, -0.5, 1]} /> */}
                {/* <Imagenes position={[1, 0, 2]} /> */}
                {/* <Triangle color="#ff2060" scale={0.009} position={[-3, 0.5, -7]} rotation={[0, 0, Math.PI / 3]} /> */}
                <Triangle color="white" scale={0.009} position={[9, 0.5, -7]} rotation={[0, 0, Math.PI / 3]} />
                {/* <Triangle color="cyan" scale={0.009} position={[4, 0, -2]} rotation={[0, 0, Math.PI / 3]} /> */}
                <Triangle color="orange" scale={0.009} position={[-5, 0, -4]} rotation={[0, 0, Math.PI / 3]} />
                {/* <Triangle color="orange" scale={0.009} position={[5, 0, 0]} rotation={[0, 0, Math.PI / 3]} /> */}
                <Triangle color="orange" scale={0.009} position={[6, 0, -2]} rotation={[0, 0, Math.PI / 3]} />
                <Triangle color="white" scale={0.009} position={[-16, 2, -10]} rotation={[0, 0, Math.PI / 3]} />
                <Ground mirror={1} blur={[500, 100]} mixBlur={12} mixStrength={1.5} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={1.5} position-y={-0.8} />
              </Rig>
              <EffectComposer multisampling={8}>
                <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
                <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
              </EffectComposer>
            </Suspense>
            <CameraShake yawFrequency={0.3} pitchFrequency={0.3} rollFrequency={0.4} intensity={1} />
          </Canvas>
        </>
      ) : (
        <h3>Sin Permiso</h3>
      )
    }
    </>
  );
};


const Logo = ({ ...props }) => {
  const texture = useLoader(THREE.TextureLoader, '/threejs/logo.png')
  return (
    <mesh {...props}>
      {/* <meshBasicMaterial attach="material" /> */}
      <planeGeometry args={[1,1]} attach="geometry" />
      {/* <Plane args={[2, 2]} /> */}
      <meshBasicMaterial transparent={true} attach="material" map={texture} />
    </mesh>
  )
}

const LogoPatagonia = ({ ...props }) => {
  const texture = useLoader(THREE.TextureLoader, '/threejs/patagonia-interactiva.png')
  const ratio = 1994/528;
  return (
    <mesh {...props}>
      {/* <meshBasicMaterial attach="material" /> */}
      <planeGeometry args={[1*ratio,1]} attach="geometry" />
      {/* <Plane args={[2, 2]} /> */}
      <meshBasicMaterial transparent={true} attach="material" map={texture} />
    </mesh>
  )
}


const Triangle = ({ color, ...props }) => {
  const ref = useRef()
  const [r] = useState(() => Math.random() * 10000)
  useFrame((_) => (ref.current.position.y = -1.75 + Math.sin(_.clock.elapsedTime + r) / 10))
  const { paths: [path] } = useLoader(SVGLoader, '/threejs/triangle.svg') // prettier-ignore
  const geom = useMemo(() => SVGLoader.pointsToStroke(path.subPaths[0].getPoints(), path.userData.style), [])
  return (
    <group ref={ref}>
      <mesh geometry={geom} {...props}>
        <meshBasicMaterial color={color} toneMapped={false} />
      </mesh>
    </group>
  )
}

const Rig = ({children}) => {
  const ref = useRef()
  const vec = new THREE.Vector3()
  const { camera, mouse } = useThree()
  useFrame(() => {
    // camera.position.lerp(vec.set(mouse.x * 2, 0, 3.5), 0.05)
    camera.position.lerp(vec.set(0, 0, 3.5), 0.05)
    // ref.current.position.lerp(vec.set(mouse.x * 1, mouse.y * 0.1, 0), 0.1)
    // ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (-mouse.x * Math.PI) / 20, 0.1)
  })
  return <group ref={ref}>{children}</group>
}


const Ground = (props) => {
  const [floor, normal] = useTexture(['/threejs/SurfaceImperfections003_1K_var1.jpg', '/threejs/SurfaceImperfections003_1K_Normal.jpg'])
  return (
    <Reflector resolution={1024} args={[8, 8]} {...props}>
      {(Material, props) => <Material color="#f0f0f0" metalness={0} roughnessMap={floor} normalMap={normal} normalScale={[2, 2]} {...props} />}
    </Reflector>
  )
}

export default PublicImages;
