import React, { useState, useEffect, useContext } from 'react';
import { Link  } from "react-router-dom";
import { InputContext } from '../contexts/InputContext';
import resourcesServices from '../services/resourcesServices';
import VotacionModal from './modals/VotacionModal';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';

const Votacion = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const participantes = []
  const timer = 5

  const [usuarios, setUsuarios] = useState([]);
  const [usuariosGanadores, setUsuariosGanadores] = useState([]);
  const [usuarioGanador, setUsuarioGanador] = useState({});

  const [running, setRunning] = useState(false);
  const [participantesEnJuego, setParticipantesEnJuego] = useState('');

  const [mostrarGanador, setMostrarGanador] = useState(false);

  const [indice, setIndice] = useState(0);
  const [iniciarRecorrido, setIniciarRecorrido] = useState(false);

  const [winner, setWinner] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * participantes.length);
        setWinner(participantes[randomIndex]);
      }, 100); // Cambia la velocidad de recorrido aquí
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, participantes]);

  useEffect(() => {
    let intervalo;

    if (iniciarRecorrido) {
      intervalo = setInterval(() => {

        // Actualiza el índice para pasar al siguiente nombre
        setIndice((prevIndice) => {
          const nuevoIndice = (prevIndice + 1) % timer;

          // Detén el intervalo después de 5 recorridos
          if (nuevoIndice === 0) {
            clearInterval(intervalo);
            setIniciarRecorrido(false); // Restablece el estado para futuros recorridos
            setMostrarGanador(true);
            guardarGanador()
          }

          return nuevoIndice;
        });
      }, 1000); // 12000 milisegundos = 12 segundos (ajusta según tus necesidades)
    }

    // Limpia el intervalo cuando el componente se desmonta o cuando se detiene el recorrido
    return () => clearInterval(intervalo);
  }, [usuarios, indice, iniciarRecorrido]);



  const handleIniciarRecorrido = () => {
    // Inicia el recorrido al presionar el botón
    setIniciarRecorrido(true);
  };

  const nuevoSorteo = () => {
    window.location.reload(false);
    // setUsuarios([]);
    // // setUsuarioGanador([])
    // setRunning(false)
  }

  const comenzar = () => {
    obtenerParticipantes()
    setRunning(true)
  }

  const guardarGanador = async () => {
    try {

      const params = {
        ganador: true
      }

      await resourcesServices.actualizarResource('multimedia_usuarios', usuarioGanador["id"], {multimedia_usuario: params});
    } catch (error) {
      errorMessage(error)
    }
  };


  const obtenerParticipantes = async () => {
    try {
      const data = await resourcesServices.obtenerResources(`multimedia_usuarios/postulantesSorteo`);
      const dataGanadores = await resourcesServices.obtenerResources(`multimedia_usuarios/postulantesSorteados`);

      const participantes = data.data.concat(data.data)

      // los participantes que tienen id_web que ya ganaron, no vuelven a participar
      const nuevasParticipaciones = participantes.filter(participante => !dataGanadores.data.some(ganador => ganador.id_web === participante.id_web))

      setUsuarios(participantes);
      setUsuarioGanador(nuevasParticipaciones[nuevasParticipaciones.length - 1])
      setParticipantesEnJuego(nuevasParticipaciones.length)

    } catch (error) {
      errorMessage(error)
    }
  };

  const obtenerGanadores = async () => {
    try {
      const data = await resourcesServices.obtenerResources(`multimedia_usuarios/postulantesSorteados`);

      setUsuariosGanadores(data.data);

    } catch (error) {
      errorMessage(error)
    }
  };


  return (
    <div>
      <h3 className='mt-3'>Votaciones
        {/* <Link className="btn btn-success ms-3" to={`/cl/${marcaActual.url_name}/voto-instagram`}>Votación Instagram</Link>  */}
        <BotonVolver /> </h3>
      <div className="row mt-3">
        <div className='col'>
          <h3>Sorteo</h3>
          <button onClick={() => obtenerGanadores()} className='btn btn-success' type='button'>Obtener Ganadores</button>
          <div>
          {
            usuariosGanadores && usuariosGanadores.length > 0 && (
              usuariosGanadores.map((ganador, index) => (
                <div key={index} className='border d-inline-block' >
                  <img width={200} src={ganador["smallImageUrl"] || ganador["imageUrl"]} className="img-thumb m-1" />
                  <br/>{ganador["nombre_usuario"]} ({ganador["id_web"]})
                </div>
              ))
            )

          }
          </div>
          {
            running ? (
              <>
                <div className={`main-message d-flex align-items-center text-center justify-content-center text-type`} style={{minHeight:'50vh'}}>
                  {
                    iniciarRecorrido || mostrarGanador ? (
                      <div>
                      {
                        mostrarGanador ? (
                          <>
                          <span className='p-2 fs-1'>Felicitaciones {usuarioGanador["nombre_usuario"]} ({usuarioGanador["id_web"]}) !!!</span>
                          <div>
                            <img width={400} src={usuarioGanador["imageUrl"]} className="img-fluid" />
                          </div>
                          </>
                        ) : (
                          <span className='p-2 fs-1'>{timer - indice}</span>
                          )
                      }
                        <div><button onClick={nuevoSorteo} className='btn btn-secondary' type='button'>Nuevo Sorteo</button></div>
                      </div>
                    ) : (
                      <>
                      <span className='p-2 text-light'>Hay {parseInt(usuarios.length/2)} participantes</span>
                      <div className='p-2'>
                        <button onClick={handleIniciarRecorrido} className='btn btn-primary' type='button'>Buscar Ganador {participantesEnJuego}</button>
                      </div>
                      </>
                    )
                  }

                </div>
              </>
            ) : (
              <div className={`main-message d-flex align-items-center text-center justify-content-center text-type`} style={{height:'50vh'}}>
                <button onClick={() => comenzar()} className='btn btn-primary' type='button'>Obtener Participantes</button>
              </div>
            )
          }
          {/* <pre>{JSON.stringify(usuarioGanador, null, 2)}</pre> */}
        </div>
      </div>


      <VotacionModal marcaActual={marcaActual}/>
    </div>
  );
};

export default Votacion;