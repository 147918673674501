import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import HeaderNew from '../components/HeaderNew';
import HomeImage from "../assets/images/deck1.jpg";
import resourcesServices from '../services/resourcesServices';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  const [eventos, setEventos] = useState([]);
  const [header, setHeader] = useState([]);

  useEffect(() => {
    obtenerEventosPublicos('', '', 1, '')
  }, []);


  const obtenerEventosPublicos = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('eventos/eventosPublicos', sortBy, q, page, otro);
      if (page === 1) {
        setEventos(data.data);
      } else {
        setEventos([...eventos, ...data.data]);
      }

      setHeader(data.headers);

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div className="container p-0 bg-main text-center">
      <Helmet>
        <title>Próximos Eventos - Bailongo</title>
        <meta name="description" content='Estos son los próximos eventos publicados en Bailongo' />
        <link rel="canonical" href={`https://bailongo.club`} />
      </Helmet>
      <HeaderNew />
      {
        eventos.length > 0 ? (
          <div className="container-fluid">
            <div className='row'>
              {
                eventos.map((evento, idx) => (
                  <div key={idx} className='col-sm-12 col-md-4 mt-3'>
                    <Link to={`${evento.friendly_url}`} style={{textDecoration: "none"}} className="card w-100 bg-blue-light rounded-0 text-start">
                      {
                        evento.imageUrl && (
                          <img src={evento.imageUrl} className="card-img-top" alt={evento.nombre} />
                        )
                      }
                      <div className="card-body">
                        <h5 className="card-title">{evento.nombre}</h5>
                        <div className="card-text">
                          {evento.fecha_inicio_formated && (<><b>{evento.fecha_inicio_formated} - {evento.hora_inicio_formated}hs</b><br /></>)}
                          {evento.ciudad && evento.ciudad.id && (<><b>Ciudad:</b> {evento.ciudad.nombre}, {evento.ciudad.provincia.nombre}<br /></>)}
                          {evento.lugar && (<><b>Lugar:</b> {evento.lugar}<br /></>)}
                          {evento.marca_id && (<><b>{evento.marca.nombre}</b><br /></>)}
                          {
                            evento.publicar_precio && (
                              evento.precio > 0 ? (<div className='my-3'><b>$ {evento.precio}</b></div>) : (<div className='my-3'><b>Gratis</b></div>)
                            )
                          }
                        </div>
                        {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                      </div>
                    </Link>
                  </div>
                ))
              }
            </div>
          </div>

        ) : (
          <a href="https://www.instagram.com/ivan.mazzei.musica/" target='_blank'>
            <img src={HomeImage} className="img-fluid mt-1" alt="logo Bailongo" />
          </a>
        )
      }
      <Footer />
    </div>
  );
};

export default Home;