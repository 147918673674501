import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import HeaderNew from '../components/HeaderNew';

const Proximamente = () => {

  return (
    <div className="container p-0 bg-main text-center">
      <HeaderNew />
      <div className="container-fluid">
        <h1>Pronto</h1>
      </div>
      <Footer />
    </div>
  );
};

export default Proximamente;