import axios from 'axios';

// Crear una nueva instancia de Axios con configuraciones personalizadas
const facebookApiClient = axios.create({
  baseURL: `https://graph.facebook.com/v19.0`, // Reemplaza esto con la URL de tu backend Rails API
  timeout: 10000, // Tiempo máximo de espera para las solicitudes en milisegundos
  headers: {
    'Authorization': "Bearer EAASYDxmVc10BOzZBPPKP5iyrGXtZBcj62ERpPzysZBn93K4lakHLFGS5qjPISpTN8g1aP88PKPpQnVYIOfaLXEM6zvxQrG4bTgwZBNJVHgZAYkt6bkiHo8m2AZAJUxE1BRicoXNSRZBHR9OsJuz7zkoX7khp5S8dLrS7p9dzNwO2qs6q251cCKoYnS8y9SwxGWKbG9knqDLmvoB7bDf",
    'Content-Type': 'application/json', // Tipo de contenido predeterminado para las solicitudes
    'Accept': 'application/json',
    // Agrega aquí cualquier encabezado adicional que necesites, como encabezados de autorización
  },
});


const facebookServices = {
  retrieveMediaUrl: async (id_image) => {
    try {
      const response = await facebookApiClient.get(`/${id_image}`);
      return response.data;

    } catch (error) {
      throw error;
    }
  },
  imageData: async (url) => {
    try {
      const response = await axios.get(url,{ headers: {
        'Authorization': "Bearer EAASYDxmVc10BOzZBPPKP5iyrGXtZBcj62ERpPzysZBn93K4lakHLFGS5qjPISpTN8g1aP88PKPpQnVYIOfaLXEM6zvxQrG4bTgwZBNJVHgZAYkt6bkiHo8m2AZAJUxE1BRicoXNSRZBHR9OsJuz7zkoX7khp5S8dLrS7p9dzNwO2qs6q251cCKoYnS8y9SwxGWKbG9knqDLmvoB7bDf"
      }});
      console.log(response)
      return response;

    } catch (error) {
      throw error;
    }
  },
  obtenerResourcePorId: async (resource) => {
    try {
      const response = await facebookApiClient.get(`/146287781907086/${resource}`);
      console.log(response)

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  actualizarResource: async (resource, id, params, isMultipart = false) => {
    try {

      let headers = {}
      if(isMultipart){
        headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      }

      const response = await facebookApiClient.put(`/${resource}/${id}`, params, headers);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  crearResource: async (resource, params, isMultipart = false) => {
    try {
      let headers = {}
      if(isMultipart){
        headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      }

      const response = await facebookApiClient.post(`/${resource}/`, params, headers);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  nuevoResource: async (resource) => {
    try {
      const response = await facebookApiClient.post(`/${resource}/new`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  eliminarResource: async (resource, id) => {
    try {
      const response = await facebookApiClient.delete(`/${resource}/${id}`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  obtenerStockPorProductoId: async (id) => {
    try {
      const response = await facebookApiClient.get(`/productos/${id}/stockPorLocal`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default facebookServices;
