import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-5 mt-5">
      <div className='row'>
        <div className='col text-start ps-5'>
          © 2024 Bailongo Social Club <br /> Contacto: +54 9 (299) 6740990
        </div>
        <div className='col'>

          {/* <Link
            className='link-success p-3 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'
            to={`/`}>Inicio</Link> */}

          <Link
            className='link-success p-3 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'
            to={`/`}>Eventos</Link>

          <Link
            className='link-success p-3 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'
            to={`/eventos-pasados`}>Eventos&nbsp;Pasados</Link>

          {/* <Link
            className='link-success p-3 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'
            to={`/pronto`}>Crear Eventos</Link> */}

          <Link
            className='link-success p-3 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'
            to={`/acceso`}>Ingreso</Link>

          {/* <Link
            className='link-success p-3 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'
            to={`/pronto`}>Ayuda</Link> */}

          <Link
            className='link-success p-3 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'
            to={`/pronto`}>Idioma</Link>

        </div>
        <div className='col'>
          <Link
          className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
          to="/politicas-de-privacidad"
          >Políticas de privacidad</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;