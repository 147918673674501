import React from 'react';
import { Link } from "react-router-dom";
import LogoBailongo from "../assets/images/logo-bailongo.png";

const HeaderNew = ({small}) => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-blue-light border-blue-strong">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <span className="navbar-brand mb-0 h1">Bailongo</span>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav me-auto"></div>
            <ul className="navbar-nav d-flex text-end">
              {/* <li className="nav-item">
                <Link className='nav-link' to={`/`}>Inicio</Link>
              </li> */}
              <li className="nav-item">
                <Link className='nav-link' to={`/`}>Eventos</Link>
              </li>
              <li className="nav-item">
                <Link className='nav-link' to={`/eventos-pasados`}>Eventos Pasados</Link>
              </li>
              {/* <li className="nav-item">
                <Link className='nav-link' to={`/pronto`}>Crear Eventos</Link>
              </li> */}
              <li className="nav-item">
                <Link className='nav-link' to={`/compartir-foto`}>Acceso</Link>
              </li>
              {/* <li className="nav-item">
                <Link className='nav-link' to={`/pronto`}>Ayuda</Link>
              </li> */}
              <li className="nav-item">
                <Link className='nav-link' to={`/pronto`}>Idioma</Link>
              </li>
            </ul>
          </div>

        </div>
      </nav>
      {
        !small && (
          <div className='header d-flex align-content-end flex-column'>
            <div className="mb-auto"><img src={LogoBailongo} className="img-fluid mt-5" alt="logo Bailongo" /></div>
            <div className="py-2">
              <div className='row row-cols-2'>
                <div className="col-sm-4 mt-3">
                  <Link className='btn btn-green' to={`/compartir-foto`}>
                    <b>Acceso</b>
                  </Link>
                </div>
                  <div className="col-sm-4 mt-3">
                  <Link className='btn btn-green' to={`/patagonia-interactiva`}>
                    <b>Patagonia</b> Interactiva
                  </Link>
                </div>
                <div className="col-sm-4 mt-3">
                  <Link className='btn btn-green' target="_blank"  to={`https://www.twitch.tv/djivanmazzei`}>
                    <b>Transmisión</b> en vivo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </>
  );
};

export default HeaderNew;
