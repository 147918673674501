import React, { useEffect, useState, useContext } from 'react';
import { Link  } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';

const VotoInstagram = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [posts, setPosts] = useState([]);
  const [winner, setWinner] = useState({});
  const [idPost, setIdPost] = useState(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    listaPosteos()
  }, []);

  useEffect(() => {
    if(idPost){
      obtenerGanador(idPost)
    }

  }, [idPost]);

  const listaPosteos = async () => {
    try {
      const data = await resourcesServices.obtenerResources('instagrams');
      setPosts(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const obtenerGanador = async (id) => {
    try {
      setWinner({})
      setStep(2)
      const data = await resourcesServices.obtenerResourcePorId('instagrams', id);
      setWinner(data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div>
      <h3 className='mt-3'>Voto Instagram <Link className="btn btn-success ms-3" to={`/cl/${marcaActual.url_name}/votacion`}>Votacion Whatsapp</Link> <BotonVolver /></h3>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <>
          {step === 1 ? (
            <div className='row'>
            {
              posts.map((post, index) => (
                <div className='col-4 mb-3' key={index}>
                  <img src={(post.media_type === 'VIDEO') ? post.thumbnail_url : post.media_url} className="img-thumbnail" />
                  <div className='text-start mt-1'>
                    Likes: {post.likes}<br />
                    Comments: {post.comments}
                  </div>
                  <div className='d-grid gap-1 mb-1 mt-1'>
                    <button className='btn btn-primary' type='button' onClick={() => setIdPost(post.id)} >Posteo</button>
                  </div>
                </div>
              ))
            }
            </div>
          ) : (
            <div className=''>
              {/* <pre>{JSON.stringify(winner, null, 2)}</pre> */}
              {
                winner && winner.winner ? (
                  <>
                  <h3>Ganador/a: {winner.winner}</h3>
                  <div className='row'>
                    <div className='col'></div>
                    <div className='col'>
                      <h5>Comentarios y etiquitas:</h5>
                      <ol className="list-group list-group-numbered text-start">
                        {
                          winner && winner.comments.map((comment, index) => (
                            <li className="list-group-item" key={index}>{comment.text}</li>
                          ))
                        }
                      </ol>
                      <button className='btn btn-primary mt-3' type='button' onClick={() => obtenerGanador(idPost)} >Buscar Nuevo Ganador</button>
                    </div>
                    <div className='col'></div>
                  </div>

                  </>
                ) : (
                  <h3>Buscando</h3>
                )
              }

            </div>
          )}
          </>

        )
      }
    </div>
  );
};

export default VotoInstagram;