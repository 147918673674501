import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import MenuPagos from './MenuPagos';

const ListaMediosDePagos = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [mediosDePagos, setMediosDePagos] = useState([]);
  // const [renderHTML, setRenderHTML] = useState(false);

  useEffect(() => {
    obtenerMediosDePagos()
  }, []);

  // useEffect(() => {
  //   if(authInformation.autenticado && ((authInformation.rol === "Organizador" || authInformation.rol === "Admin"))){
  //     setRenderHTML(true)
  //   }
  // }, [authInformation]);

  const obtenerMediosDePagos = async (otro = '&estado=') => {
    try {
      const data = await resourcesServices.obtenerResources('medios_de_pagos', otro);
      setMediosDePagos(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };



  const eliminarMedioDePago = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar el pago ?')){
        return;
      }

      await resourcesServices.eliminarResource('medios_de_pagos', id);
      const copiaPagos = [...mediosDePagos];
      setMediosDePagos(copiaPagos.filter((mediosDePagos) => mediosDePagos.id !== id))

      // obtenerUsuarios()
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("\n"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div>
      <h3>Medios de Pagos</h3>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <>
          <MenuPagos marcaActual={marcaActual} />

          {/* <div className="btn-group ms-3" role="group" aria-label="Basic example">
            <button onClick={()=>obtenerUsuarios('&estado=')} className='btn btn-outline-secondary'>Todos</button>
            <button onClick={()=>obtenerUsuarios('&estado=activo')} className='btn btn-outline-secondary'>Activos</button>
            <button onClick={()=>obtenerUsuarios('&estado=bloqueado')} className='btn btn-outline-secondary'>Bloqueados</button>
          </div> */}
            <ul className="list-group list-group-flush mt-3">
              {
                mediosDePagos.map((medioDePago, index) => (
                  <li key={index} className="list-group-item text-start">
                    <span className='ms-1'><b>{medioDePago && medioDePago.nombre}</b></span>
                    <span className='ms-1'>(recargo del {medioDePago && medioDePago.recargo} %)</span>

                    <div className="btn-group ms-3" role="group" aria-label="Basic example">
                      <Link className="btn btn-success" to={`/cl/${marcaActual.url_name}/medio-de-pago/${medioDePago.id}`}>Editar</Link>
                      <button onClick={()=>eliminarMedioDePago(medioDePago.id)} className='btn btn-danger'>Eliminar</button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </>
        )
      }

    </div>
  );
};

export default ListaMediosDePagos;