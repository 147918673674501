import React, { useState, useEffect} from 'react';

/*
Si label no está definido, con la funcion convertSnakeCaseToText convierto el atributo name en label
*/
const TextControl = ({ value, name, handleInput, label, type='text', hasLabel=true, notDiv=false }) => {
  const [caption, setCaption] = useState('');

  useEffect(() => {
    if (label===undefined){
      if (name.includes('.')) {
        const [parentKey, childKey] = name.split('.');
        setCaption( convertSnakeCaseToText(childKey));
      } else {
        setCaption( convertSnakeCaseToText(name));
      }
    } else {
      setCaption(label)
    }
  }, [name]);

  const convertSnakeCaseToText = (snakeCaseString) => {
    const words = snakeCaseString.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  return (
    <>
    {notDiv ? (
      <input 
      type={type}
      step={type==='number' ? '0.001' : '0'}
      name={name}
      value={value || ''} 
      className="form-control"
      onChange={(e) => handleInput(e)}
      placeholder={caption}
      id={name} />
    ) : (
      <div className={(type !== 'hidden' && hasLabel) ? 'form-floating mb-3' : '' }>
      <input 
        type={type}
        step={type==='number' ? '0.001' : '0'}
        name={name}
        value={value || ''} 
        className="form-control"
        onChange={(e) => handleInput(e)}
        placeholder={caption}
        id={name} />
        { type !== 'hidden' && hasLabel && (<label htmlFor={name}>{caption}</label>) }
      </div>
    )}
    </>
  );
};

export default TextControl;