import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import resourcesServices from '../services/resourcesServices';
import { errorMessage } from '../errorHandler';

const ReceivedJson = () => {
  const [messages, setMessages] = useState([]);
  const [header, setHeader] = useState([]);

  useEffect(() => {
    obtenerMessages('', '', 1, '')
  }, []);


  const obtenerMessages = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('uuebs/receivedJson', sortBy, q, page, otro);
      if (page === 1) {
        setMessages(data.data);
      } else {
        setMessages([...messages, ...data.data]);
      }

      setHeader(data.headers);

    } catch (error) {
      errorMessage(error)
    }
  };

  return (
    <div className="container p-0 bg-main text-center">
      {
        messages.length > 0 ? (
          <div className="container-fluid">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Source</th>
                  <th scope="col">Json</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
              {
                messages.map((message, idx) => (
                  <tr key={message.id}>
                    <th scope="row">{message.id}</th>
                    <td>{message.source}</td>
                    <td>
                      <p className="d-inline-flex gap-1">
                        <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsed_${message.id}`} aria-expanded="false" aria-controls="collapseExample">
                          JSON
                        </button>
                      </p>
                      <div className="collapse" id={`collapsed_${message.id}`}>
                        <div className="card card-body">
                        <pre className='text-start'>{JSON.stringify(message.json, null, 2) }</pre>
                        </div>
                      </div>

                    </td>
                    <td>{message.formated_date}</td>
                  </tr>
                ))
              }
              </tbody>
            </table>
            {
              header["total-pages"] !== header["current-page"] && (
                <button onClick={()=>obtenerMessages('', '', header["current-page"] + 1, '')} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
              )
            }
          </div>

        ) : (
          <h3>Sin mensajes</h3>
        )
      }
      <Footer />
    </div>
  );
};

export default ReceivedJson;