import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';

const ListaUsuarios = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [usuarios, setUsuarios] = useState([]);
  const [header, setHeader] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    obtenerUsuarios('', '', 1, '')
  }, []);

  const obtenerUsuarios = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('usuarios', sortBy, q, page, otro);
      if (page === 1) {
        setUsuarios(data.data);
      } else {
        setUsuarios([...usuarios, ...data.data]);
      }

      setHeader(data.headers);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const colorEstadoUsuario = (estado) => {
    if(estado === "activo"){
      return "text-bg-secondary"
    } else if(estado === "bloqueado"){
      return "text-bg-danger"
    }

  }

  const estadoUsuario = async (id, marca_id, estado) => {
    try {
      const data = await resourcesServices.actualizarResource('usuarios', `${id}/bloquearUsuarioMarca`, {usuario: {marca_id: marca_id, estado: estado}});
      console.log(data)
      setUsuarios(prevDatos => {
        return prevDatos.map(objeto => {
          if (objeto.id === data.id) {
            // Si encontramos el objeto con el ID, actualizamos el valor
            return { ...objeto, usuarios_marcas: data.usuarios_marcas };
          }
          // Si no es el objeto que estamos buscando, lo dejamos sin cambios
          return objeto;
        });
      });

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const eliminarUsuario = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar el usuario ?')){
        return;
      }

      await resourcesServices.eliminarResource('usuarios', id);
      const copiaUsuarios = [...usuarios];
      setUsuarios(copiaUsuarios.filter((usuarios) => usuarios.id !== id))

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };


  const handleFormSubmit = (e) => {
    e.preventDefault()

    obtenerUsuarios('', query, 1, '')
  }

  return (
    <div>
      <h3>Usuarios</h3>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <>
          <h5>Total: {header && header["total-count"]}</h5>
          {/* <div className="btn-group ms-3" role="group" aria-label="Basic example">
            <button onClick={()=>obtenerUsuarios('&estado=')} className='btn btn-outline-secondary'>Todos</button>
            <button onClick={()=>obtenerUsuarios('&estado=activo')} className='btn btn-outline-secondary'>Activos</button>
            <button onClick={()=>obtenerUsuarios('&estado=bloqueado')} className='btn btn-outline-secondary'>Bloqueados</button>
          </div> */}
          <form className="row row-cols-lg-auto g-3 align-items-center" onSubmit={handleFormSubmit}>
            <div className="col-12">
              <label htmlFor="staticEmail2" className="visually-hidden">Nombre apellido o teléfono</label>
              <input type="text" onChange={(e) => setQuery(e.target.value)} className="form-control" id="staticEmail2" value={query || ''} />
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary">Buscar</button>
            </div>
          </form>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Datos</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>

              {
                usuarios.map((usuario, index) => (
                  <tr key={index}>
                    <th scope="row">{usuarios.length - index}</th>
                    <td className='text-start'>
                      <span className={`badge text-bg-primary`}>{usuarios.length - index}</span>
                      <span className={`ms-1 badge ${colorEstadoUsuario(usuario.estado)}`}>{usuario.estado}</span>
                      <span className='ms-1'><b>{usuario && usuario.nombre_completo}</b></span>
                      <div className='ms-3'><b>Rol:</b> {usuario && usuario.rol}</div>
                      <div className='ms-3'><b>Phone:</b> {usuario && usuario.phone}</div>
                      <div className='ms-3'><b>Alta:</b> {usuario && usuario.fecha_alta}</div>
                    </td>
                    <td>
                      {
                        authInformation.rol === "Admin" && (
                          <>
                            <Link target="_blank" className="btn btn-info" to={`/login/${usuario.uuid}`} >Login As</Link>
                            <Link className="btn btn-success" to={`/cl/${marcaActual.url_name}/usuarioForm/${usuario.id}`} >Modificar</Link>
                            <button onClick={()=>eliminarUsuario(usuario.id)} className='btn btn-danger'>Eliminar</button>
                          </>
                        )
                      }

                      {
                        usuario && usuario.usuarios_marcas.map((um, i) => (
                          <div key={i}>
                            {
                              um.estado === 'activo' ? (
                                <button onClick={()=>estadoUsuario(usuario.id, um.marca_id, "bloqueado")} className='btn btn-warning'>Bloquear para {um.marca_nombre}</button>
                              ) : (
                                <button onClick={()=>estadoUsuario(usuario.id, um.marca_id, "activo")} className='btn btn-success'>Activar para {um.marca_nombre}</button>
                              )
                            }
                          </div>
                        ))
                      }

                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
            {
              header["total-pages"] !== header["current-page"] && (
                <button onClick={()=>obtenerUsuarios('', query, header["current-page"] + 1, '')} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
              )
            }
          </>
        )
      }

    </div>
  );
};

export default ListaUsuarios;