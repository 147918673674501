import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import TextControl from '../components/customizedInputs/TextControl';
import TextAreaControl from './customizedInputs/TextAreaControl';
import SelectControl from './customizedInputs/SelectControl';
import BotonVolver from './BotonVolver';

const MensajeForm = ({marcaActual}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [esNuevo, setEsNuevo] = useState(true);
  const [mensaje, setMensaje] = useState({mensajes_usuarios: []});
  const [usuarios, setUsuarios] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [header, setHeader] = useState([]);
  const [estadoMensajesUsuarioActual, setEstadoMensajesUsuarioActual] = useState('');
  const [query, setQuery] = useState("");

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(id !== undefined){
      obtenerMensaje(id)
      obtenerMensajesUsuarios(id)
    } else {
      nuevoMensaje()
    }

    obtenerEventos();

  }, [id]);

  useEffect(() => {
    if(!esNuevo){
      obtenerMensajesUsuarios(id, '', '', 1, `estadoMensajesUsuario=${estadoMensajesUsuarioActual}`)
    }
  }, [estadoMensajesUsuarioActual]);

  const obtenerMensaje = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('mensajes', id);
      setMensaje(data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        console.log('error', error.response.data.error)
        // navigate(`/`);
      }
    }
  };

  const obtenerMensajesUsuarios = async (id, sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources(`mensajes_usuarios`, sortBy, q, page, `mensaje_id=${id}&${otro}`);
      if (page === 1) {
        setUsuarios(data.data);
      } else {
        setUsuarios([...usuarios, ...data.data]);
      }

      setHeader(data.headers);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404 || error.response.status===401){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const nuevoMensaje = async () => {
    try {
      const data = await resourcesServices.nuevoResource('mensajes');
      setMensaje({...data, tipo: "directo"});
    } catch (error) {
      console.log('error', error)
    }
  };

  const obtenerEventos = async () => {
    try {
      const data = await resourcesServices.obtenerResources('eventos');
      setEventos(data.data);
    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const guardarMensaje = async () => {
    try {
      let data;
      if(esNuevo){
        data = await resourcesServices.crearResource('mensajes', {mensaje: mensaje});

        alert("Datos actualizados")
        navigate(`/cl/${marcaActual.url_name}/mensaje/${data.id}`);
      } else {
        data = await resourcesServices.actualizarResource('mensajes', id, {mensaje: mensaje});
        setMensaje(data);

        alert("Datos actualizados")
      }


    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };


  const guardarEstadoUsuario = async (mensajes_usuario_id, usuario_id, estado) => {
    try {
      // mensaje.update({mensajes_usuarios_attributes: [{id: 877, usuario_id: 132, estado: "enviado"}]})
      await resourcesServices.actualizarResource('mensajes', id, {mensajes_usuarios_attributes: [{id: mensajes_usuario_id, usuario_id: usuario_id, estado: estado}]});
      obtenerMensajesUsuarios(id, '', '', 1, `estadoMensajesUsuario=${estadoMensajesUsuarioActual}`)
      alert("Datos actualizados")


    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };

  const WhatsappURL = (usuario) => {
    let whatsappMessage = mensaje.mensaje.replace(/\n/g,'%0A').replace(/\[FULLNAME\]/g, usuario.usuario.nombre_completo);
    whatsappMessage = whatsappMessage.replace(/\n/g,'%0A').replace(/\[NAME\]/g, usuario.usuario.nombre);
    whatsappMessage = whatsappMessage.replace(/\n/g,'%0A').replace(/\[SURNAME\]/g, usuario.usuario.apellido);

    return `https://web.whatsapp.com/send?phone=${usuario.phone_usuario}&text=${whatsappMessage}`
  }

  const GuardarComoEnviado = (usuario) => {
    navigator.clipboard.writeText(WhatsappURL(usuario))
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    setMensaje({...mensaje,
        [name]: value
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    guardarMensaje()
  }

  const handleFormSubmitQuery = (e) => {
    e.preventDefault()
    obtenerMensajesUsuarios(id, '', query, 1, '')
  }

  return (
    <div>
        <main className="w-100 m-auto" style={{maxWidth: '660px'}}>
          <h5 className='mt-3'>Mensaje <BotonVolver /></h5>
          <form className='text-start'>
            <TextControl
                value={mensaje.titulo}
                name={'titulo'}
                handleInput={handleInput}
              />

            <div className="mb-3">
              <SelectControl
                  value={mensaje.evento_id}
                  options={eventos}
                  name={'evento_id'}
                  handleInput={handleInput}
                  label={'Seleccione el evento (opcional)'}
                />
            </div>

            <p className="fw-lighter">
              [NAME]: Si el primer nombre existe, es reemplazado por el nombre del usuario<br />
              [SURNAME]: Si el apellido existe, es reemplazado por el nombre del usuario<br />
              [FULLNAME]: Si el nombre completo existe, es reemplazado por el nombre del usuario<br />
            </p>

            <TextAreaControl
                value={mensaje.mensaje}
                name={'mensaje'}
                handleInput={handleInput}
                height={'200px'}
              />


            <div className="">
              <button className="btn btn-primary mt-3" onClick={handleFormSubmit} type="submit">Guardar</button>
            </div>

          </form>

          {/* <pre>{JSON.stringify(usuariosSeleccionados, null, 2)}</pre> */}
          {
            mensaje && mensaje.titulo && (
              <div>
                <h5>Usuarios</h5>
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                  {
                    mensaje && mensaje["estadosMensajesUsuarios"].map((estado, index) => (
                      <div key={index} className='ms-1'>
                      <input
                        type="radio"
                        className="btn-check"
                        name={`radio${index}`}
                        id={`radio${index}`}
                        value={estado}
                        autoComplete="off"
                        checked={estadoMensajesUsuarioActual === estado}
                        onChange={(e) => setEstadoMensajesUsuarioActual(e.target.value)}
                        />
                      <label className="btn btn-outline-primary" htmlFor={`radio${index}`}>{estado}</label>
                      </div>
                    ))
                  }
                </div>

                {
                  usuarios.length > 0 && (
                    <form className="row row-cols-lg-auto g-3 align-items-center mt-1" onSubmit={handleFormSubmitQuery}>
                      <div className="col-12">
                        <label htmlFor="staticEmail2" className="visually-hidden">Nombre apellido o teléfono</label>
                        <input type="text" onChange={(e) => setQuery(e.target.value)} className="form-control" id="staticEmail2" value={query || ''} />
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary">Buscar</button>
                      </div>
                    </form>
                  )
                }
                <ul className="list-group list-group-flush mt-3">
                  {
                    usuarios && usuarios.map((usuario, index) => (
                      <li key={index} className={`list-group-item text-start ${ usuario.estado === 'bloqueado' ? 'bg-warning' : '' }`}>
                        <div className='row'>
                          <div className='col-2'>
                            {usuario.estado}
                          </div>
                          <div className='col'>
                            {/* <span className={`badge text-bg-primary`}>{usuarios.length - index}</span> */}
                            <span className='ms-1'><b>{usuario && usuario.nombre_usuario} ({usuario.rol_usuario})</b></span>
                            <div className=''> {usuario && usuario.phone_usuario}
                              {
                                usuario.estado === 'bloqueado' && (
                                  <span className={`badge text-bg-danger ms-1`}>B</span>
                                )
                              }
                              {
                                (usuario.rol_usuario === 'Organizador' || usuario.rol_usuario === 'Admin') && (
                                  <span className={`badge text-bg-warning ms-1`}>Organizador</span>
                                )
                              }
                            </div>
                          </div>
                          <div className='col'>

                            <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                              <button onClick={() => GuardarComoEnviado(usuario)} type="button" className="btn btn-warning">Copiar URL Whatsapp</button>
                              {/* <Link className='btn btn-warning' target="_blank" to={WhatsappURL(usuario)}>Abrir Whatsapp</Link> */}
                              {
                                usuario.estado !== "enviado" && (
                                  <>
                                  <button onClick={() => guardarEstadoUsuario(usuario.id, usuario.usuario_id, "enviado")} type="button" className="btn btn-success">Guardar como enviado</button>
                                  <button onClick={() => guardarEstadoUsuario(usuario.id, usuario.usuario_id, "inexistente")} type="button" className="btn btn-danger">Guardar como inexistente</button>
                                  <button onClick={() => guardarEstadoUsuario(usuario.id, usuario.usuario_id, "no_invitado")} type="button" className="btn btn-info">Guardar como no invitado</button>
                                  </>
                                )
                              }
                            </div>

                          </div>
                        </div>
                      </li>
                    ))
                  }
                </ul>

                {
                  header["total-pages"] !== header["current-page"] && (
                    <button onClick={()=>obtenerMensajesUsuarios(id, '', '', header["current-page"] + 1, `estadoMensajesUsuario=${estadoMensajesUsuarioActual}`)} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
                  )
                }

              </div>
            )
          }
        </main>
    </div>
  );
};

export default MensajeForm;