import React from 'react';

const MedioDePago = () => {
  return (
    <div className='text-start'>
      <h1 id="mediopago">Formas y Medios de Pago</h1>

      <div className='block-kp'>
        <b>En qué moneda se paga ?</b><br />
        Los pagos al contado son en pesos haciendo una conversión a dolar blue.<br />
        Para quienes manejen stable coins, tambíen se puede pagar en USDT (theters). 1 USD = 1 USDT<br />
      </div>

      <div className='block-kp'>
        <b>Medios de pago</b><br />
        Los pueden pueden ser por transferencia a través de un ALIAS/CBU o con tarjeta de crédito a través de Mercado Pago
      </div>

      <div className='block-kp'>
      <b>Facilidades:</b><br />
        Con el fin de ofrecer facilidades a los alumnos, en Kizomba Patagonia ofrecemos pagos mensuales para que no paguen el interés que cobran las tarjetas de crédito. Los pagos se acuerdan con el alumno.<br /><br />
        Ejemplo de pagos acordados sin tarjeta de crédito:<br />
        Si el alumno compra un Full Pass de USD 135 en febrero, se puede pagar en 4 pagos mensuales de USD 33,75:<br />
        Febrero abona USD 33,75, Marzo abona USD 33,75, Abril abona USD 33,75, Mayo abona USD 33,75<br /><br />

        Si el alumno comienza a pagar en Marzo, los pagos se dividen en 3 pagos de USD 45:<br />
        Marzo abona USD 45, Abril abona USD 45, Mayo abona USD 45

      </div>

      <div className='block-kp'>
      <b>IMPORTANTE - En caso de cancelación:</b><br />
        Dado que los valores son expresados en dólares, en el caso que, por cuestiones de fuerza mayor, el evento se cancele, se realizará la devolución del dinero correspondiente al curso o la/s cuotas abonadas.<br />
        Ejemplo: Si se abonó un <b>Full Pass</b> de USD 135, se devolverán los USD 135 convertidos a pesos según el valor promedio del dolar blue compra contra venta del día de la devolución.<br />
        Si se abonó una cuota de USD 35, se devolverán los USD 35 convertidos a pesos según el valor promedio del dolar blue compra contra venta del día de la devolución.<br />
        Consultar por devolución en Tethers.
      </div>
    </div>
  );
};

export default MedioDePago;