import axios from 'axios';

// Crear una nueva instancia de Axios con configuraciones personalizadas
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`, // Reemplaza esto con la URL de tu backend Rails API
  timeout: 10000, // Tiempo máximo de espera para las solicitudes en milisegundos
  headers: {
    'Content-Type': 'application/json', // Tipo de contenido predeterminado para las solicitudes
    'Accept': 'application/json', 
    // Agrega aquí cualquier encabezado adicional que necesites, como encabezados de autorización
  },
});

// Opcional: Agregar interceptores para realizar acciones adicionales antes o después de las solicitudes
apiClient.interceptors.request.use(
  (config) => {
    // Realizar acciones antes de enviar la solicitud, si es necesario

    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Manejar errores de solicitud, si es necesario
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    // Realizar acciones después de recibir la respuesta, si es necesario
    return response;
  },
  (error) => {
    // Manejar errores de respuesta, si es necesario
    return Promise.reject(error);
  }
);

export const errorHandling = (error) => {
  if( error.response && (error.response.status === 401) ) {
    alert(error.response.data.error)
  } else {
    alert("error inesperado")
  }
}

export const errorConsole = (error, area='') => {
  console.error(`Error ${area!=='' ? 'en '+area : ''}:`, error);
}

export default apiClient;

