import React, { useEffect, useState, useContext }  from 'react';
import { Link } from "react-router-dom";
import { InputContext } from '../contexts/InputContext';

const Mensajeria = ({marcaActual}) => {
  const [authInformation, setAuthInformation] = useContext(InputContext)

  return (
    <div>
      <h3>Mensajería</h3>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <div className='row'>
            <div className='col d-flex'>
              <Link className='btn btn-success bg-gradient p-5 w-100 rounded-0 fs-4' to={`/cl/${marcaActual.url_name}/lista-mensajes-template`}>Templates</Link>
            </div>
            <div className='col d-flex'>
              <Link className='btn btn-success bg-gradient p-5 w-100 rounded-0 fs-4' to={`/cl/${marcaActual.url_name}/lista-mensajes`}>Mensaje Directo Whatsapp</Link>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Mensajeria;