import React, { createContext, useState } from "react";
import authServices from "../services/authServices";

const InputProvider = (props) => {
    const [authInformation, setAuthInformation] = useState({});

    const checkSignIn = async (uuid) => {
        try {
            const data = await authServices.checkSignIn();

            if(data.token){
                setAuthInformation({
                    token: data.token,
                    nombre: data.nombre,
                    user_id: data.user_id,
                    rol: data.rol,
                    marca: data.marca,
                    condiciones_aceptadas: data.condiciones_aceptadas,
                    cambio_de_nombre: data.cambio_de_nombre,
                    autenticado: true,
                })
                localStorage.setItem('token', data.token);

            } else {
                setAuthInformation({autenticado: false})
                localStorage.removeItem('token');
            }

        } catch (error) {
            setAuthInformation({autenticado: false})
            localStorage.removeItem('token');
        }
    };

    return (
        <InputContext.Provider
            value={[authInformation, setAuthInformation, checkSignIn]}
        >
            {props.children}
        </InputContext.Provider>
    )
}
export default InputProvider;
export const InputContext = createContext({});
