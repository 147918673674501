import React from 'react';

const Politicas = () => {
  return (
    <div className='text-start'>
      <p><b>Fecha de vigencia: 19/11/2023</b></p>

      <p>Gracias por visitar nuestro sitio web. En <b>Bailongo Social Club</b>, entendemos la importancia de la privacidad en línea y nos comprometemos a proteger la información personal que usted proporciona a través de nuestro sitio. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos su información personal. Al utilizar nuestro sitio web, acepta los términos de esta política de privacidad.</p>

      <h4>Información que Recopilamos</h4>

      <p>Recopilamos información que usted nos proporciona directamente, como su nombre, dirección de correo electrónico, información de contacto y otra información personal cuando completa formularios en nuestro sitio web. También podemos recopilar información automáticamente a través de cookies y tecnologías similares para mejorar su experiencia en nuestro sitio.</p>

      <h4>Uso de la Información</h4>

      <p>Utilizamos la información que recopilamos para brindarle los servicios que solicita, personalizar su experiencia en nuestro sitio web y mejorar nuestros servicios. No compartiremos su información personal con terceros sin su consentimiento, a menos que sea necesario para cumplir con la ley o para proteger nuestros derechos.</p>

      <h4>Cookies y Tecnologías Similares</h4>

      <p>Utilizamos cookies y tecnologías similares para recopilar información sobre su actividad en nuestro sitio web. Puede configurar su navegador para rechazar cookies, pero tenga en cuenta que esto puede afectar su experiencia en el sitio.</p>

      <h4>Seguridad de la Información</h4>

      <p>Tomamos medidas para proteger su información personal y garantizar su seguridad. Sin embargo, ningún sistema en línea es completamente seguro, y no podemos garantizar la seguridad absoluta de la información que comparte con nosotros.</p>

      <h4>Enlaces a Terceros</h4>

      <p>Nuestro sitio web puede contener enlaces a sitios de terceros. No somos responsables de las prácticas de privacidad de estos sitios y le recomendamos revisar sus políticas de privacidad.</p>

      <h4>Cambios en esta Política</h4>

      <p>Nos reservamos el derecho de actualizar o modificar esta política de privacidad en cualquier momento. Cualquier cambio importante se comunicará a través de nuestro sitio web. Le recomendamos que revise periódicamente esta política para estar al tanto de nuestras prácticas de privacidad más recientes.</p>

      <h4>Contacto</h4>

      <p>Si tiene alguna pregunta o inquietud sobre esta política de privacidad, por favor contáctenos a <b>ynventa@gmail.com</b>.</p>

      <p>Gracias por confiar en <b>Bailongo Social Club</b> con su información personal. Estamos comprometidos a proteger su privacidad y a proporcionarle una experiencia segura en nuestro sitio web.</p>
    </div>
  );
};

export default Politicas;