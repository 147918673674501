import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { InputContext } from '../contexts/InputContext';
import authServices from '../services/authServices';
import TextControl from '../components/customizedInputs/TextControl';
import HeaderNew from '../components/HeaderNew';
import Footer from '../components/Footer';

const Login = () => {
  let { uuid } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)
  const [datosUsuario, setDatosUsuario] = useState({password: null, marca_id: null});
  const [estadoAcceso, setEstadoAcceso] = useState(0); //0: pendiente, 1: accediendo, 2: elegir marca, 3: sin acceso
  const [marcas, setMarcas] = useState([]);

  useEffect(() => {
    if(uuid){
      signIn(uuid)
    }
  }, [uuid, datosUsuario.marca_id]);


  const signIn = async (uuid) => {
    try {
      setEstadoAcceso(1)
      const response = await authServices.signIn({uuid: uuid, ...datosUsuario});
      const data = response.data

      if(response.status === 200 && data.token){
        setAuthInformation({
          token: data.token,
          nombre: data.nombre,
          user_id: data.user_id,
          rol: data.rol,
          marca: data.marca,
          condiciones_aceptadas: data.condiciones_aceptadas,
          autenticado: true,
        })
        localStorage.setItem('token', data.token);
        navigate(`/cl/${data.marca.url_name}`);
      } else {
        if(response.status === 202){
          setMarcas(data)
          setEstadoAcceso(2)
        } else {
          setAuthInformation({token: undefined, nombre: undefined, user_id: undefined, rol: undefined, autenticado: false})
          localStorage.removeItem('token');
        }
      }
    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        setEstadoAcceso(3)
        //alert(error.response.data.error)
        // console.log('error', error.response.data.error)
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setDatosUsuario({...datosUsuario,
        [name]: value
    })
  }
  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (!datosUsuario.password) {
      alert('Por favor, ingrese su contraseña.');
      return;
    }
    signIn(uuid)
  }

  const setMarca = (id) => {
    setDatosUsuario({...datosUsuario, 'marca_id': id})
  }

  return (
    <div className="container p-0 bg-main text-center">
      <HeaderNew small={true} />
      <div className='m-5 p-5'>
        {
          (estadoAcceso === 0 || estadoAcceso === 1) && (
            <>Accediendo ...</>
          )
        }
        {
          estadoAcceso === 2 && (
            <>
            <h3>Elija donde entrar</h3>
            <p>Cada marca ofrece diferentes propuestas</p>

            <div className="list-group">
              {
                marcas && marcas.map((marca, index) => (
                  <button key={index} onClick={() => setMarca(marca.marca_id)} className="text-start list-group-item list-group-item-dark">{marca.marca.nombre}</button>
                ))
              }
            </div>
            </>
          )
        }
        {
          estadoAcceso === 3 && (
            <form>
            <h3>Ingrese su contraseña</h3>
            <TextControl
              value={datosUsuario.password}
              name={'password'}
              handleInput={handleInput}
              type='password'
              label='Clave'
              />

            {/* <Link className="btn btn-link mb-3" to="/recuperoClave">Olvidaste la clave ?</Link> */}

            <button className="btn btn-primary mt-3" onClick={handleFormSubmit} type="submit">Entrar</button>
          </form>

          )
        }
      </div>
      <Footer />
    </div>
  );
};

export default Login;