import React from 'react';

const Precios = () => {
  return (
    <div className='text-start'>
      <h1 id="precios">Precios</h1>

      <div className='row mb-3 text-center'>
        <div className='col-sm-4'>
          <div className='card mb-4 rounded-3 shadow-sm'>
            <div className='card-header py-3'>
              <h4 className='my-0 fw-normal'>Full Pass</h4>
            </div>
            <div className='card-body'>
              <h1 className='card-title'><small className='text-body-secondary fw-light'>USD</small> 135</h1>
              <ul className='list mt-3 mb-4 text-start'>
                <li>3 encuentros (Marzo, Abril y Mayo)</li>
                <li>26 horas de clases en 6 días</li>
                <li>Intensivo Kizomba</li>
                <li>Master: Urban Kiz, Fusion, Tarraxo</li>
                <li>Musicalidad: Llevar y Seguir</li>
                <li>Zouk para Bachateros: Primer mes gratis para alumnos</li>
                <li>Pase gratis para Social Night Sábado y Domingo</li>

              </ul>
              {/* <button className='w-100 btn btn-lg btn-outline-primary'>Comprar</button> */}
            </div>
            <div className='card-footer text-body-secondary'>
              Pagá en hasta 4 cuotas mensuales (Febrero, Marzo, Abril y Mayo)
            </div>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className='card mb-4 rounded-3 shadow-sm'>
            <div className='card-header py-3'>
              <h4 className='my-0 fw-normal'>Full Intensivo</h4>
            </div>
            <div className='card-body'>
              <h1 className='card-title'><small className='text-body-secondary fw-light'>USD</small> 90</h1>
              <ul className='list mt-3 mb-4 text-start'>
                <li>3 encuentros (Marzo, Abril y Mayo)</li>
                <li>18 horas de clases en 6 días</li>
                <li>Intensivo Kizomba</li>
                <li>Musicalidad: Llevar y Seguir</li>
                <li>Zouk para Bachateros: Primer mes gratis para alumnos</li>
                <li>Pase gratis para Social Night Sábado y Domingo</li>
                <li className='text-decoration-line-through text-danger'>Master: Urban Kiz, Fusion, Tarraxo</li>

              </ul>
              {/* <button className='w-100 btn btn-lg btn-outline-primary'>Comprar</button> */}
            </div>
            <div className='card-footer text-body-secondary'>
              Pagá en hasta 4 cuotas mensuales (Febrero, Marzo, Abril y Mayo)
            </div>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className='card mb-4 rounded-3 shadow-sm'>
            <div className='card-header py-3'>
              <h4 className='my-0 fw-normal'>Full Master</h4>
            </div>
            <div className='card-body'>
              <h1 className='card-title'><small className='text-body-secondary fw-light'>USD</small> 65</h1>
              <ul className='list mt-3 mb-4 text-start'>
                <li>3 encuentros (Marzo, Abril y Mayo)</li>
                <li>15 horas de clases en 6 días</li>
                <li>Master: Urban Kiz, Fusion, Tarraxo</li>
                <li>Musicalidad: Llevar y Seguir</li>
                <li>Zouk para Bachateros: Primer mes gratis para alumnos</li>
                <li className='text-decoration-line-through text-danger'>Intensivo Kizomba</li>
                <li className='text-decoration-line-through text-danger'>Pase gratis para Social Night Sábado y Domingo</li>

              </ul>
              {/* <button className='w-100 btn btn-lg btn-outline-primary'>Comprar</button> */}
            </div>
            <div className='card-footer text-body-secondary'>
              Pagá en hasta 4 cuotas mensuales (Febrero, Marzo, Abril y Mayo)
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-5 text-center'>
        <div className='col-sm-6'>
          <div className='card mb-4 rounded-3 shadow-sm'>
            <div className='card-header py-3'>
              <h4 className='my-0 fw-normal'>Intensivo Kizomba (valor por encuentro)</h4>
            </div>
            <div className='card-body'>
              <h1 className='card-title'><small className='text-body-secondary fw-light'>USD</small> 35</h1>
              <ul className='list mt-3 mb-4 text-start'>
                <li>1 encuentro (sábado y domingo)</li>
                <li>Intensivo Kizomba</li>
                <li>Musicalidad: Llevar y Seguir</li>
                <li>Zouk para Bachateros: Primer mes gratis para alumnos</li>
                <li className='text-decoration-line-through text-danger'>Master: Urban Kiz, Fusion, Tarraxo</li>
                <li className='text-decoration-line-through text-danger'>Pase gratis para Social Night Sábado y Domingo</li>

              </ul>
              {/* <button className='w-100 btn btn-lg btn-outline-primary'>Comprar</button> */}
            </div>
            <div className='card-footer text-body-secondary'>
              Pagá en hasta 2 cuotas
            </div>
          </div>
        </div>

        <div className='col-sm-6'>
          <div className='card mb-4 rounded-3 shadow-sm'>
            <div className='card-header py-3'>
              <h4 className='my-0 fw-normal'>Master de ritmos (valor por encuentro)</h4>
            </div>
            <div className='card-body'>
              <h1 className='card-title'><small className='text-body-secondary fw-light'>USD</small> 25</h1>
              <ul className='list mt-3 mb-4 text-start'>
                <li>1 encuentro (sábado y domingo)</li>
                <li>Master: Urban Kiz, Fusion, Tarraxo</li>
                <li>Musicalidad: Llevar y Seguir</li>
                <li>Zouk para Bachateros: Primer mes gratis para alumnos</li>
                <li className='text-decoration-line-through text-danger'>Intensivo Kizomba</li>
                <li className='text-decoration-line-through text-danger'>Pase gratis para Social Night Sábado y Domingo</li>

              </ul>
              {/* <button className='w-100 btn btn-lg btn-outline-primary'>Comprar</button> */}
            </div>
            <div className='card-footer text-body-secondary'>
              Pagá en hasta 2 cuotas
            </div>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Precios;