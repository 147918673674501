import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';

const Eventos = ({marcaActual}) => {
  const navigate = useNavigate();
  const [authInformation] = useContext(InputContext)
  const openRef = useRef(null);
  const [eventos, setEventos] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('abierto');
  const [header, setHeader] = useState([]);

  useEffect(() => {
    obtenerEventos('', '', 1, 'estado=abierto')
  }, []);

  useEffect(() => {
    if(selectedFilter!==null){
      obtenerPagina()
    }
  }, [selectedFilter]);

  const obtenerPagina = async (page=1) => {
    if(selectedFilter!==null){
      obtenerEventos('', '', page, 'estado=' + selectedFilter)
    } else {
      obtenerEventos('', '', page, '')
    }
  };

  const abrirModal = () => {
    openRef.current.click();
  }

  const obtenerEventos = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('eventos', sortBy, q, page, otro);
      if (page === 1) {
        setEventos(data.data);
      } else {
        setEventos([...eventos, ...data.data]);
      }

      setHeader(data.headers);

    } catch (error) {
      errorMessage(error)
    }
  };

  const eliminarEvento = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar el evento ?')){
        return;
      }

      await resourcesServices.eliminarResource('eventos', id);
      actualizarEventos()
    } catch (error) {
      errorMessage(error)
    }
  };

  const duplicarEvento = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere duplicar el evento ?')){
        return;
      }

      const data = await resourcesServices.actualizarResource(`eventos`, `${id}/duplicar`);
      navigate(`/cl/${marcaActual.url_name}/eventoForm/${data.id}`);

    } catch (error) {
      errorMessage(error)
    }
  };

  const actualizarEventos = () => {
    obtenerEventos('', '', 1)
  }


  return (
    <div>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") ? (
          <h3>Eventos</h3>
        ) : (
          <h3 className='mt-3'>Eventos Vigentes <BotonVolver /></h3>
        )
        }
        {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <>
          <Link className="btn btn-primary" to={`/cl/${marcaActual.url_name}/eventoForm`}>Nuevo</Link> <BotonVolver />
          </>
        )
      }
      {/* +<pre>{JSON.stringify(marcaActual, null, 2)}</pre>+ */}
      {
        eventos.length === 0 ? (
          authInformation.rol === "Interactivo" ? (
            <h5>No hay eventos en este momento</h5>
          ) : (
            <h5>Aún no hay eventos</h5>
          )
        ) : (
          <>
          {(authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
            <div>
              <div className="btn-group mt-3" role="group" aria-label="Basic example">
                <button onClick={()=>setSelectedFilter('abierto')} className='btn btn-outline-secondary'>Abiertos</button>
                <button onClick={()=>setSelectedFilter('cerrado')} className='btn btn-outline-secondary'>Cerrados</button>
                <button onClick={()=>setSelectedFilter('')} className='btn btn-outline-secondary'>Todos</button>
              </div>
            </div>
          )}

          <div className="card mt-3">
            <ul className="list-group list-group-flush">
              {
                eventos.map((evento, index) => (
                  <li key={index} className="list-group-item text-start">
                    {(authInformation.rol === "Organizador" || authInformation.rol === "Admin") ? (
                      <>
                      {evento.nombre}
                      <span className='ms-3'>( {evento.estado} )</span>
                      <div className="btn-group ms-3" role="group" aria-label="Basic example">
                        <Link className="btn btn-success" to={`/cl/${marcaActual.url_name}/eventoForm/${evento.id}`}>Editar</Link>
                        <button onClick={()=>duplicarEvento(evento.id)} className='btn btn-warning'>Duplicar</button>
                        <button onClick={()=>eliminarEvento(evento.id)} className='btn btn-danger'>X</button>
                      </div>
                      </>
                    ) : (
                      <Link className="btn " to={`/cl/${marcaActual.url_name}/evento/${evento.id}`}>{evento.nombre}</Link>
                    )}
                  </li>
                ))
              }
            </ul>
          </div>

            {
              header["total-pages"] !== header["current-page"] && (
                <button onClick={()=>obtenerPagina(header["current-page"] + 1)} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
              )
            }
          </>
        )
      }

    </div>
  );
};

export default Eventos;