import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';

const EventoShow = ({marcaActual}) => {
  let { id } = useParams();

  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [participacion, setParticipacion] = useState(false);
  const [evento, setEvento] = useState({});
  const [esNuevo, setEsNuevo] = useState(true);

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(id){
      fetchEvento(id)
    }
  }, [id]);


  const fetchEvento = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('eventos', id);
      const part = data.usuarios_eventos && data.usuarios_eventos.filter(ue => (ue.usuario_id === authInformation.user_id));
      if(part.length > 0) {
        setParticipacion(part[0])
      } else {
        setParticipacion(false)
      }

      setEvento(data);
    } catch (error) {
      // Manejo de errores aquí
      console.error('Error al obtener las categorías:', error);
    }
  };


  const guardarParticipacion = async () => {
    try {
      if(participacion){
        await resourcesServices.eliminarResource('usuarios_eventos', participacion.id);
      } else {
        const data = await resourcesServices.crearResource('usuarios_eventos', {usuarios_evento: {evento_id: evento.id}});
        setParticipacion(data)
      }

    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        // handleShowModal(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        console.log('error', error.error)
      }
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if(window.confirm("Estás seguro que querés participar de los sorteos ?")){
      guardarParticipacion()
    }
  }


  return (
    <div>
      {
        (evento && evento.nombre) && (
          <>
          <div className="card text-start mt-3">
            <div className="card-body">
              <h5 className="card-title">{evento.nombre} <BotonVolver /></h5>

              <div className="card-text" dangerouslySetInnerHTML={{ __html: evento.descripcion_html }} />
              {
                evento.link_pago && (
                  <a className='btn btn-primary' href={evento.link_pago} target='_blank'>Pagar</a>
                  )
              }
            </div>
            {/* <ul className="list-group list-group-flush">
              <li className="list-group-item">An item</li>
              <li className="list-group-item">A second item</li>
              <li className="list-group-item">A third item</li>
            </ul> */}

            {/* <Payment
              initialization={initialization}
              customization={customization}
              onSubmit={onSubmit}
              onReady={onReady}
              onError={onError}
            /> */}

            <div className="card-body">
              {/* <pre>{JSON.stringify(participacion, null, 2)}</pre> */}
              {
              participacion ? (
              <div className="alert alert-primary" role="alert">
                Estás participando de los sorteos
              </div>
              ) : (
                <a href="#" onClick={(e)=>handleFormSubmit(e)} className="btn btn-dark">
                Participar de los sorteos
                </a>
              )
              }
              {/* <a href="#" className="card-link">Ver fotos</a> */}
              {/* <button type="button" className="btn btn-secondary ms-3" onClick={() => cerrarModal()} >Volver</button> */}
            </div>

            {
              evento.imageUrl && (
                <img src={evento.imageUrl} className="img-fluid" alt={evento.nombre} />
              )
            }

          </div>
          </>

        )
      }
    </div>
  );
};

export default EventoShow;