import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { InputContext } from '../contexts/InputContext';
import resourcesServices from '../services/resourcesServices';
import TextControl from '../components/customizedInputs/TextControl';
import SelectControl from '../components/customizedInputs/SelectControl';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';

const DatosUsuario = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)
  const [usuario, setUsuario] = useState({});
  const [saving, setSaving] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchUsuario(id || authInformation.user_id)
  }, [id]);

  useEffect(() => {
    if( authInformation.autenticado === true && authInformation.rol === 'Admin' ){
      fetchRoles()
      fetchMarcas()
    }
  }, [authInformation]);

  const fetchUsuario = async (user_id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('usuarios', user_id);
      setUsuario(data);
    } catch (error) {
      errorMessage(error)
    }
  };

  const fetchRoles = async () => {
    try {
      const data = await resourcesServices.obtenerResources('usuarios/obtenerRoles');
      setRoles(data.data);
    } catch (error) {
      errorMessage(error)
    }
  };

  const fetchMarcas = async () => {
    try {
      const data = await resourcesServices.obtenerResources('marcas');
      console.log(data.data)
      // const aux = data.data.map((rol) => {"id": rol.id, "nombre": rol.nnombre})
      setMarcas(data.data);
    } catch (error) {
      errorMessage(error)
    }
  };

  const guardarUsuario = async () => {
    try {
      setSaving(true)

      // const copiaUsuario = {...usuario}
      // copiaUsuario.preferencias_usuario_attributes = copiaUsuario.preferencias_usuario
      // delete copiaUsuario.preferencias_usuario

      const data = await resourcesServices.actualizarResource('usuarios', id || authInformation.user_id, {usuario: usuario});
      setUsuario(data);
      checkSignIn();
      setSaving(false)

      alert("Datos actualizados")

    } catch (error) {
      setSaving(false)
      errorMessage(error)
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      // usado para valores anidados
      const [parentKey, childKey] = name.split('.');
      setUsuario((usuario) => ({
        ...usuario,
        [parentKey]: {
          ...usuario[parentKey],
          [childKey]: value,
        },
      }));
    } else if(name === 'tiene_ano_nacimiento'){
      setUsuario({...usuario,
        [name]: !usuario.tiene_ano_nacimiento
      })

    } else {
      setUsuario({...usuario,
        [name]: value
    })

    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    guardarUsuario()
  }

  return (
    <div>
        <main className="w-100 m-auto" style={{maxWidth: '660px'}}>
          <h3 className='mt-3'>Datos Personales <BotonVolver /></h3>
          <form>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="user-tab" data-bs-toggle="tab" data-bs-target="#user-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Datos</button>
              </li>
              {authInformation.rol === "Admin" && (
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="marca-tab" data-bs-toggle="tab" data-bs-target="#marca-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Marcas</button>
                </li>
              )}
            </ul>
            <div className="tab-content pt-3" id="myTabContent">
              <div className="tab-pane fade show active" id="user-tab-pane" role="tabpanel" aria-labelledby="user-tab" tabIndex="0">

                  <TextControl
                    value={usuario.nombre}
                    name={'nombre'}
                    handleInput={handleInput}
                  />

                <TextControl
                    value={usuario.apellido}
                    name={'apellido'}
                    handleInput={handleInput}
                  />

                <label className="form-check-label" htmlFor="flexCheckDefault">Fecha de nacimiento</label>
                {/* <pre>{JSON.stringify(usuario, null, 2)}</pre> */}
                <div className="input-group">
                  <TextControl
                    value={usuario.dia_nacimiento}
                    name={'dia_nacimiento'}
                    handleInput={handleInput}
                    notDiv={true}
                    type="number"
                    label={"Día"}
                  />
                  <TextControl
                    value={usuario.mes_nacimiento}
                    name={'mes_nacimiento'}
                    handleInput={handleInput}
                    notDiv={true}
                    type="number"
                    label={"Mes"}
                  />
                  {usuario.tiene_ano_nacimiento &&
                    <TextControl
                      value={usuario.ano_nacimiento}
                      name={'ano_nacimiento'}
                      handleInput={handleInput}
                      notDiv={true}
                      type="number"
                      label={"Año"}
                    />
                    }
                  <div className="input-group-text">
                    <input className="form-check-input mt-0"
                      type="checkbox"
                      name="tiene_ano_nacimiento"
                      checked={usuario.tiene_ano_nacimiento || ''}
                      onChange={(e) => handleInput(e)}
                      value=""
                      />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      &nbsp;Mostrar año
                    </label>
                  </div>
                </div>

              </div>
              {(authInformation.rol === "Admin" || authInformation.rol === "Organizador") && (
                <div className="tab-pane fade text-start" id="marca-tab-pane" role="tabpanel" aria-labelledby="marca-tab" tabIndex="0">
                <h3>Marcas Asociadas</h3>
                {/* {
                  usuario.usuarios_marcas && usuario.usuarios_marcas.map((um, idx) => (
                    <div key={idx}>
                        <SelectControl
                          value={um.rol}
                          options={roles}
                          name={`usuarios_marcas.${idx}.rol`}
                          handleInput={handleInput}
                          label={'Seleccione el rol'}
                        />
                        <SelectControl
                          value={um.marca_id}
                          options={marcas}
                          name={`usuarios_marcas.${idx}.marca_id`}
                          handleInput={handleInput}
                          label={'Seleccione el marca'}
                        />
                    </div>
                  ))
                } */}
                </div>
              )}
            </div>


            <div className="">
              <button disabled={saving}  className="btn btn-primary mt-3" onClick={handleFormSubmit} type="submit">
                {saving && (<span className="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>)}
                <span role="status">Guardar</span>
              </button>
            </div>

          </form>
        </main>
    </div>
  );
};

export default DatosUsuario;