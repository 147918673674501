import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';
import facebookServices from '../services/facebookServices';

const ConfirmacionWhatsapp = ({marcaActual}) => {
  const navigate = useNavigate();
  const [authInformation] = useContext(InputContext)
  const [whatsapps, setWhatsapps] = useState([]);

  useEffect(() => {
    obtenerWhatsappSinConfirmacion()
  }, []);

  const obtenerWhatsappSinConfirmacion = async () => {
    try {
      const data = await resourcesServices.obtenerResources('uuebs/paraConfirmar');
      setWhatsapps(data.data);

    } catch (error) {
      errorMessage(error)
    }
  };


  const confirmarMensaje = async (wa) => {
    try {
      let image_data = {}
      if(wa.content_type === 'image'){
        image_data = await facebookServices.retrieveMediaUrl(wa.image_id);
      }

      const data = await resourcesServices.actualizarResource('uuebs', `${wa.id}/setConfirmacion`, {image: image_data});
      // setWhatsapps(  )
    } catch (error) {
      errorMessage(error)
    }
  };



  return (
    <div>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
          <div>
            <h3>Whatsapps</h3>
            {
            whatsapps.length > 0 && (
              <>
              <div className="card mt-3">
                <ul className="list-group list-group-flush">
                  {
                    // idWA
                    // id
                    // phoneFrom
                    // content_type
                    // estado
                    // image_id



                    whatsapps.map((wa, index) => (
                      <li key={index} className="list-group-item text-start">
                        <>
                        {wa.phoneFrom}
                        <span className='ms-3'>( {wa.content_type} )</span>
                        <div className="btn-group ms-3" role="group" aria-label="Basic example">
                          <button onClick={()=>confirmarMensaje(wa)} className='btn btn-success'>Confirmar</button>
                        </div>
                        </>
                      </li>
                    ))
                  }
                </ul>
              </div>
              </>
            )}
          </div>
        )
      }

    </div>
  );
};

export default ConfirmacionWhatsapp;