import React, {useContext, useState, useEffect} from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { InputContext } from '../contexts/InputContext';
import DatosUsuario from './DatosUsuario';
import PlayList from './PlayList';
import Eventos from './Eventos';
import MiMultimedia from './MiMultimedia';
import ListaUsuarios from './ListaUsuarios';
import Votacion from './Votacion';
import VotoInstagram from './VotoInstagram';
import ListaMensajesTemplates from './ListaMensajesTemplates';
import MensajeTemplateForm from './MensajeTemplateForm';
import ListaMensajes from './ListaMensajes';
import MensajeForm from './MensajeForm';
import ListaMarcas from './ListaMarcas';
import MarcaForm from './MarcaForm';
import ListaPagos from './ListaPagos';
import MedioDePagoForm from './MedioDePagoForm';
import ListaMediosDePagos from './ListaMediosDePagos';
import PagoForm from './PagoForm';
import Mensajeria from './Mensajeria';
import EventoForm from './EventoForm';
import EventoShow from './EventoShow';
import Juegos from './Juegos';
import CompartidoEventos from './CompartidoEventos';
import Posteos from './Posteos';
import PosteoForm from './PosteoForm';
import ConfirmacionWhatsapp from './ConfirmacionWhatsapp';


const ImmUsuario = () => {
  const navigate = useNavigate();
  let { url_client, area } = useParams();
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [marcaActual, setMarcaActual] = useState(false)

  useEffect(() => {
    if(authInformation.autenticado === true){
      if(url_client !== undefined && url_client !== authInformation.marca.url_name){
        navigate(`/cl/${authInformation.marca.url_name}`);
      }
      setMarcaActual(authInformation.marca)
    }
  }, [url_client, authInformation]);

  return (
    <div className="container-fluid">
      <div className='mb-3'>
        {
          marcaActual && marcaActual.url_name && (
            <>
              <nav className="navbar navbar-expand-lg border-bottom bg-white">
                <div className="container-fluid">
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="navbar-nav me-auto"></div>
                    <ul className="navbar-nav d-flex text-end">
                      {authInformation.rol === "Interactivo" && (
                        <>
                          <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}`}>Home</Link>
                          </li>
                          <li className="nav-item">
                            {/* <Link className="nav-link" to={`/cl/${marcaActual.url_name}/eventos`}>Eventos Ahora</Link> */}
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/compartido-eventos`}>Compartido</Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/playlist`}>PlayList</Link>
                          </li> */}
                          {/* <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/multimedia`}>Multimedia</Link>
                          </li> */}
                          <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/juegos`}>Juegos</Link>
                          </li>
                        </>
                      )}


                      { (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
                        <>
                          <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/eventos`}>Eventos</Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/usuarios`}>Usuarios</Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/multimedia`}>Multimedia</Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/votacion`}>Votación</Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/mensajeria`}>Mensajería</Link>
                          </li> */}
                          {/* <li className="nav-item">
                            <Link className="nav-link" to={`/cl/${marcaActual.url_name}/pagos`}>Pagos</Link>
                          </li> */}
                          {
                            authInformation.rol === "Admin" ? (
                              <>
                              <li className="nav-item">
                                <Link className="nav-link" to={`/cl/${marcaActual.url_name}/marcas`}>Marcas</Link>
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to={`/cl/${marcaActual.url_name}/posteos`}>Posteos</Link>
                              </li>
                              <li className="nav-item">
                                <Link target="_blank" className="nav-link" to={`/galeria-multimedia`}>Galeria Multimedia</Link>
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to={`/cl/${marcaActual.url_name}/confirmacion-whatsapp`}>Confirmación de Whatsapp</Link>
                              </li>
                              </>
                            ) : (
                              <li className="nav-item">
                                <Link className="nav-link" to={`/cl/${marcaActual.url_name}/marca/${marcaActual.id}`}>Marcas</Link>
                              </li>
                            )
                          }
                        </>
                      )}
                      <li className="nav-item">
                        <Link className="nav-link" to={`/cl/${marcaActual.url_name}/usuario`}>Datos Personales</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to={`/logout`}>Salir</Link>
                      </li>

                    </ul>
                  </div>

                </div>
              </nav>
              <div className=''>
                { area === 'usuario' && <DatosUsuario /> }
                { area === 'playlist' && <PlayList /> }
                { area === 'eventos' && <Eventos marcaActual={marcaActual}/> }
                { area === 'multimedia' && <MiMultimedia /> }
                { area === 'votacion' && <Votacion marcaActual={marcaActual}/> }
                { area === 'voto-instagram' && <VotoInstagram marcaActual={marcaActual} /> }
                { area === 'usuarios' && <ListaUsuarios marcaActual={marcaActual} /> }
                { area === 'confirmacion-whatsapp' && <ConfirmacionWhatsapp marcaActual={marcaActual} /> }

                { area === 'posteoForm' && <PosteoForm marcaActual={marcaActual} /> }
                { area === 'posteos' && <Posteos marcaActual={marcaActual} /> }

                { area === 'usuarioForm' && <DatosUsuario marcaActual={marcaActual} /> }

                { area === 'eventoForm' && <EventoForm marcaActual={marcaActual} /> }
                { area === 'evento' && <EventoShow marcaActual={marcaActual} /> }

                { area === 'juegos' && <Juegos marcaActual={marcaActual} /> }
                { area === 'compartido-eventos' && <CompartidoEventos marcaActual={marcaActual} /> }


                { area === 'mensajeria' && <Mensajeria marcaActual={marcaActual} /> }
                { area === 'lista-mensajes-template' && <ListaMensajesTemplates marcaActual={marcaActual} /> }
                { area === 'mensaje-template' && <MensajeTemplateForm marcaActual={marcaActual} /> }
                { area === 'lista-mensajes' && <ListaMensajes marcaActual={marcaActual} /> }
                { area === 'mensaje' && <MensajeForm marcaActual={marcaActual} /> }

                { area === 'pagos' && <ListaPagos marcaActual={marcaActual} /> }
                { area === 'pago' && <PagoForm marcaActual={marcaActual} /> }
                { area === 'medios-de-pagos' && <ListaMediosDePagos marcaActual={marcaActual} /> }
                { area === 'medio-de-pago' && <MedioDePagoForm marcaActual={marcaActual} /> }

                { area === 'marcas' && <ListaMarcas marcaActual={marcaActual} /> }
                { area === 'marca' && <MarcaForm marcaActual={marcaActual} /> }
                { area === undefined && (authInformation.rol === "Organizador" || authInformation.rol === "Admin") && (
                    <Eventos marcaActual={marcaActual} />
                )}
                { area === undefined && authInformation.rol === "Interactivo" && (
                    <div className='py-3'>
                      {
                        authInformation.cambio_de_nombre ? (
                          <>
                            <h2>Su nombre está en un formato incorrecto</h2>
                            <div>Actualmente su nombre está registrado como: <b>{authInformation.nombre}</b></div>

                            <Link className="btn btn-success mt-3" to={`/cl/${authInformation.marca.url_name}/usuario`}>Actualice su nombre</Link>
                          </>
                        ) : (
                            <>
                              {/* <div><Link className="btn btn-green mb-3" to={`/cl/${marcaActual.url_name}/eventos`}>Eventos Ahora</Link></div> */}
                              <div><Link className="btn btn-green mb-3" to={`/cl/${marcaActual.url_name}/compartido-eventos`}>Lo que compartiste</Link></div>
                              <div><Link className="btn btn-green mb-3" to={`/cl/${marcaActual.url_name}/juegos`}>Juegos</Link></div>
                              <div><Link className="btn btn-green mb-3" to={`/cl/${marcaActual.url_name}/usuario`}>Datos Personales</Link></div>
                            </>
                        )
                      }
                    </div>
                )}
              </div>
            </>
          )
        }

      </div>
    </div>
  );
};

export default ImmUsuario;