import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import Politicas from './Politicas';

const ImmAceptaCondiciones = () => {
  const [authInformation, setAuthInformation] = useContext(InputContext)

  const aceptarCondiciones = async () => {
    try {
      const data = await resourcesServices.actualizarResource('usuarios', authInformation.user_id, {usuario: {acepta_condiciones: true}});

      setAuthInformation({...authInformation,
        condiciones_aceptadas: data.acepta_condiciones
      })

    } catch (error) {
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===401){
        // console.log('error', error.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div>
      <h2>Acepte los términos y condiciones para seguir avanzando</h2>

      <Politicas />

      <div className="btn-group" role="group" aria-label="Basic mixed styles example">
        <button onClick={aceptarCondiciones} type="button" className="btn btn-success">Acepto</button>
        <Link className="btn btn-danger" to={`/logout`}>No acepto</Link>
      </div>
    </div>
  );
};

export default ImmAceptaCondiciones;