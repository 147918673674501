import React from 'react';
import { useNavigate } from "react-router-dom";

const BotonVolver = () => {
  const navigate = useNavigate();

  return (
    <>
      <button onClick={() => navigate(-1)} className="btn btn-orange ms-3" ><b>Volver</b></button>
    </>
  );
};

export default BotonVolver;