import React, {useRef, useState} from 'react';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import pantallaCasino from '../assets/images/pantalla-casino-2.jpeg';
import logoPatagoniaInteractiva from '../assets/images/logo-patagonia-interactiva-2.png';
import HeaderNew from '../components/HeaderNew';

const Servicio = () => {
  const listElement = useRef(null);

  const [images, setImages] = useState([
    'multimedia-1.png',
    'multimedia-2.png',
    'multimedia-3.png',
    'multimedia-4.png',
    'multimedia-5.png',
    'multimedia-6.png',
    'multimedia-7.png',
    'multimedia-8.png',
    'multimedia-9.png',
    'multimedia-10.png',
    'multimedia-11.png',
    'multimedia-12.png',
  ])

  const MasInformacion = () => {
    return (
      <div className="text-center my-3">
        <Link className='btn btn-green' target="_blank" to={`https://wa.me/5492996740990?text=Quiero información sobre la aplicación`}>
          <b>Quiero</b> más Información
        </Link>
      </div>
    )
  }

  return (
    <div className="container p-0 bg-main text-center">
      <HeaderNew />
      <div className="container-fluid mt-3">

        <div className='row mb-3'>
          <div className='col-2'></div>
          <div className='col'><img src={logoPatagoniaInteractiva} className='img-fluid' alt="Patagonia Interactiva" /></div>
          <div className='col-2'></div>
        </div>

        <div className='row mb-3'>
          <div className='col-2'></div>
          <div className='col'>
            <div className="h-100 text-dark fs-4 p-5 bg-light bg-gradient border rounded-3">
              <p>
              Sumergite en la innovación con Patagonia Interactiva,
              tu compañero esencial para <b>eventos sociales y corporativos</b>. A través de nuestro
              exclusivo servicio, llevamos la experiencia interactiva a nuevas alturas. Utilizando
              tecnología de vanguardia como <b>Deep Learning y Visión por Computadora</b>, creamos
              interacciones únicas e innovadoras. <b>Desde WhatsApp</b>, tu audiencia se involucra
              con el evento mediante <b>imágenes, textos, trivias y sorteos</b>, creando momentos
              memorables y llenos de diversión.
              ¡Haz que cada evento sea inolvidable con Patagonia Interactiva, donde la <b>tecnología y
              la diversión se encuentran</b>!
              </p>
              {/* <button className="btn btn-outline-secondary" type="button">Example button</button> */}
            </div>
          </div>
          <div className='col-2'></div>
        </div>


        <div className='row mb-3'>
          <div className='col-2'></div>
          <div className='col'><img src={pantallaCasino} className='img-thumbnail' alt="Ivan Mazzei" /></div>
          <div className='col-2'></div>
        </div>

        <MasInformacion />

        <div className='text-center'>
          <h6>Eventos corporativos y sociales</h6>
          <h4>Whatsapp -> Instagram -> Facebook</h4>
          <h1>Interactividad y participación</h1>
          <h4>Desde el celular y sin aplicaciones</h4>
          <h6>Desde Whatsapp con acceso a TODO</h6>
        </div>

        <div className='row'>
          <div className='col-2'></div>
          <div className='col'>

          <div id="carouselExampleIndicators" className="carousel slide mt-3" data-bs-ride="carousel">
            <div className="carousel-inner">
              {
                images && images.map((image, index) => (
                  <div key={index} data-bs-interval="4000" className={`carousel-item ${index===0 ? 'active' : ''}`}>
                    <img src={`/multimedia/${image}`} className="d-block w-100" alt={`${image}`} />
                  </div>
                ))
              }
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <div className='text-end'>
              <div className="badge bg-primary text-wrap" style={{width: "6rem"}}>
                Imágenes de ejemplo de un evento
              </div>
            </div>
          </div>
          <div className='col-2'></div>
        </div>


        <div className='row'>
          <div className='col-2'></div>
          <div className='col'>
            <iframe className='embed-responsive-item' width='768' height='433' src={`https://www.youtube.com/embed/pxAqPFIkB20?si=BfqxpqrwaQE29UgR&modestbranding=1&controls=0&amp;`} title='Patagonia Interactiva' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowborder='0' allowFullScreen></iframe>
          </div>
          <div className='col-2'></div>
        </div>

        <div className='text-start p-3 my-5 bg-dark text-white bg-gradient'>
          <b>Tus invitados solo necesitan Whatsapp para compartir momentos. Podrán compartir fotos o mensajes. Ej:</b>
          <ul>
            <li className='font-monospace'><i>Feliz cumple, Marina. Te queremos. El tío Nacho y la tía Ro</i></li>
            <li className='font-monospace'><i>Excelente música. Felicitaciones al DJ</i></li>
            <li className='font-monospace'><i>Quiero pedir el último tema de Abel Pintos</i></li>
          </ul>
        </div>

        <div className='row'>
          <div className='col-2'></div>
          <div className='col'>
            <iframe className='embed-responsive-item' width='768' height='433' src={`https://www.youtube.com/embed/gDtOSY13agA?si=Ws8eTdQoIrJSPOhT&modestbranding=1&controls=0&amp;`} title='Patagonia Interactiva' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowborder='0' allowFullScreen></iframe>
          </div>
          <div className='col-2'></div>
        </div>

        <MasInformacion />

        <div className="row align-items-md-stretch mt-1 text-white">
          <div className="col-md-6 mt-3">
            <div className="h-100 p-5 bg-dark bg-gradient rounded-3">
              <h2>Compartílo en la pantalla</h2>
              <p>Todo lo que tus invitados compartan desde su Whatsapp (fotos y textos) puede ser mostrado en la pantalla de tu evento.</p>
              {/* <button className="btn btn-outline-light" type="button">Example button</button> */}
            </div>
          </div>
          <div className="col-md-6 mt-3 text-white">
            <div className="h-100 text-dark p-5 bg-light bg-gradient border rounded-3">
              <h2>Filtrado de mensajes</h2>
              <p>Cada foto o mensaje compartido pasa previamente por un filtro de aprobación o rechazo de muy simple uso.</p>
              {/* <button className="btn btn-outline-secondary" type="button">Example button</button> */}
            </div>
          </div>

          <div className="col-md-6 mt-3 text-white">
            <div className="h-100 p-5 bg-dark bg-gradient rounded-3">
              <h2>Participación en sorteos</h2>
              <p>Tus invitados pueden participar de sorteos desde la misma plataforma</p>
            </div>
          </div>
          <div className="col-md-6 mt-3 text-white">
            <div className="h-100 text-dark p-5 bg-light bg-gradient border rounded-3">
              <h2>Album de recuerdos</h2>
              <p>Todas fotos y mensajes compartidos por los invitados queda guardado como recuerdo para ser vistos a través de un link</p>
            </div>
          </div>

          <div className="col-md-6 mt-3 text-white">
            <div className="h-100 p-5 bg-dark bg-gradient rounded-3">
              <h2>Sin instalación de aplicaciones</h2>
              <p>La plataforma no requiere instalación de aplicaciones. Nuestro objetivo es hacerlo simple. Solo se necesita tener instalado Whatsapp, datos o wifi en el teléfono para poder enviar las fotos y los mensajes</p>
            </div>
          </div>
        </div>

        <MasInformacion />
      </div>



      <Footer />
    </div>
  );
};

export default Servicio;