import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';

const Posteos = ({marcaActual}) => {
  const [authInformation] = useContext(InputContext)
  const [posteos, setPosteos] = useState([]);
  const [header, setHeader] = useState([]);
  const [renderHTML, setRenderHTML] = useState(false);

  useEffect(() => {
    if(authInformation.autenticado && authInformation.rol === "Admin"){
      obtenerSocialContents('', '', 1)
      setRenderHTML(true)
    }
  }, [authInformation]);

  const obtenerPagina = async (page=1) => {
    obtenerSocialContents('', '', page, '')
  };

  const obtenerSocialContents = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('social_contents', sortBy, q, page, otro);
      if (page === 1) {
        setPosteos(data.data);
      } else {
        setPosteos([...posteos, ...data.data]);
      }

      setHeader(data.headers);

    } catch (error) {
      errorMessage(error)
    }
  };

  const eliminarSocialContent = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar el contenido ?')){
        return;
      }

      await resourcesServices.eliminarResource('social_contents', id);
      obtenerSocialContents('', '', 1)
    } catch (error) {
      errorMessage(error)
    }
  };

  return (
    <div>
      { renderHTML &&
        <>
        <h3 className='mt-3'>Social Contents <BotonVolver /></h3>

        <Link className="btn btn-primary" to={`/cl/${marcaActual.url_name}/posteoForm`}>Nuevo</Link>

        {/* +<pre>{JSON.stringify(marcaActual, null, 2)}</pre>+ */}
        {
          posteos.length === 0 ? (
            <h5>Aún no hay posteos</h5>
          ) : (
            <>

            <div className='row text-start'>
              {
                posteos.map((posteo, idx) => (
                  <div key={idx} className='col-sm-12 col-md-4 mt-3'>
                    {
                      posteo.file_type === 'image' && posteo.mediaUrl && (
                        <img src={posteo.mediaUrl} className="card-img-top" alt={posteo.nombre} />
                      )
                    }
                    {
                      posteo.file_type === 'video' && posteo.mediaUrl && (
                        <div className="alert alert-info" role="alert">
                          Esto es un video
                        </div>

                      )
                    }
                    <div className="card-body">
                      <div className="card-text">
                        <b>Fecha de inicio de posteo:</b> {posteo.start_datetime_formated}<br />
                        <b>Días de posteo:</b> {posteo.times}<br />
                        <b>Días de publicado:</b> {posteo.times_posted}<br />
                        <b>Tipo de publicación:</b> {posteo.media_type}<br />
                      </div>
                      <button onClick={()=>eliminarSocialContent(posteo.id)} className='btn btn-danger mt-3'>Eliminar</button>
                      {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                    </div>
                  </div>
                ))
              }
            </div>

              {
                header["total-pages"] !== header["current-page"] && (
                  <button onClick={()=>obtenerPagina(header["current-page"] + 1)} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
                )
              }
            </>
          )
        }
      </>
    }
    </div>
  );
};

export default Posteos;