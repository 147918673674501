import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import { useParams } from "react-router-dom";
import HeaderNew from '../components/HeaderNew';
import resourcesServices from '../services/resourcesServices';
import { Helmet } from 'react-helmet-async';
import { errorMessage } from '../errorHandler';

const MultimediaUsuario = () => {
  let { usuario_id } = useParams();
  const [imagenes, setImagenes] = useState([]);
  const [header, setHeader] = useState([]);

  useEffect(() => {
    obtenerMultimediaUsuario('', '', 1, 'usuario_id='+usuario_id)
  }, []);


  const obtenerMultimediaUsuario = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('uuebs/multimediaUsuario', sortBy, q, page, otro);
      if (page === 1) {
        setImagenes(data.data);
      } else {
        setImagenes([...imagenes, ...data.data]);
      }

      setHeader(data.headers);

    } catch (error) {
      errorMessage(error)
    }
  };

  return (
    <div className="container p-0 bg-main text-center">
      <Helmet>
        <title>Tus Fotos</title>
        <meta name="description" content='Tus fotos' />
        <link rel="canonical" href={`https://bailongo.club`} />
      </Helmet>
      <HeaderNew />
      {
        imagenes.length > 0 ? (
          <div className="container-fluid">
            <div className='row'>
              {
                imagenes.map((imagen, idx) => (
                  <div key={idx} className='col-sm-12 col-md-4 mt-3'>
                    imagen {idx}
                  </div>
                ))
              }
            </div>
          </div>

        ) : (
          <h5>Aún no hay imagenes</h5>
        )
      }
      <Footer />
    </div>
  );
};

export default MultimediaUsuario;