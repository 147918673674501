import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import { Link, useParams } from "react-router-dom";
import HeaderNew from '../components/HeaderNew';
import resourcesServices from '../services/resourcesServices';
import BotonVolver from '../components/BotonVolver';
import { Helmet } from 'react-helmet-async';

const Evento = () => {
  let { id } = useParams();
  const [evento, setEvento] = useState({});

  useEffect(() => {
    if(id){
      obtenerEventoPublico(id)
      //requestAI()
    }
  }, [id]);

  const requestAI = async () => {
    try {
      const params = {
        prompt: "Cuantos feriados tiene el mes de noviembre en Argentina?"
      }
      const data = await resourcesServices.crearResource(`eventos/llm`, params);
      console.log(data);

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  const obtenerEventoPublico = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId(`eventos`, `${id}/eventoPublico`);
      setEvento(data);

    } catch (error) {
      // console.log('error', error)
      if(error.response.status===422 && error.response.data.errors.length > 0){
        alert(error.response.data.errors.join("<br />"))
      } else if(error.response.status===404){
        // console.log('error', error.response.data.error)
        alert(error.response.data.error)
      }
    }
  };

  return (
    <div className="container p-0 bg-main text-center">
      <Helmet>
        <title>{evento.nombre || ''} - Bailongo</title>
        <meta name="description" content={evento.seo_description || ''} />
        <link rel="canonical" href={evento.friendly_url || ''} />
      </Helmet>
      <HeaderNew />
      {
        evento.nombre && (
          <div className='container-fluid text-start my-3'>
            <div className="float-end"><BotonVolver /></div>
            <h1>{evento.nombre}</h1>
            <div className='border-top fs-4 border-bottom border-5 my-3 py-3'>
              {evento.fecha_inicio_formated && (<><b>{evento.fecha_inicio_formated} {evento.hora_inicio_formated}hs</b><br /></>)}
              {evento.ciudad && evento.ciudad.id && (<><b>Ciudad:</b> {evento.ciudad.nombre}, {evento.ciudad.provincia.nombre}<br /></>)}
              {evento.lugar && (<><b>Lugar:</b> {evento.lugar}<br /></>)}
              {evento.marca_id && (<><b>{evento.marca.nombre}</b><br /></>)}
              {
                evento.publicar_precio && (
                  evento.precio > 0 ? (<div className='my-3'><b>$ {evento.precio}</b></div>) : (<div className='my-3'><b>Gratis</b></div>)
                )
              }
              {
                evento.link_pago_vigente && (
                  <Link to={`${evento.link_pago_vigente}`} target="_blank" className="btn btn-green" ><b>Sacar Entrada</b></Link>
                )
              }
            </div>

            <div className="card mb-3 border-0">
              <div className="row g-0">
                {
                  evento.imageUrl && (
                    <div className="col-md-5">
                      <img src={evento.imageUrl} className="img-fluid" alt={evento.nombre} />
                    </div>
                  )
                }
                <div className={`${evento.imageUrl ? 'col-md-7' : ''}`}>
                  <div className="card-body pt-0">
                    <div className="card-text" dangerouslySetInnerHTML={{ __html: evento.descripcion_html }} />
                    {/* <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
      }

      <Footer />
    </div>
  );
};

export default Evento;