import apiClient, {errorConsole} from '../apiClient';

const authServices = {
  signIn: async (datos) => {
    try {
      const response = await apiClient.post(`/auth/login`, datos);
      return response;
    } catch (error) {
      throw error;
    }
  },
  checkSignIn: async () => {
    //TODO: verificar que los admin puedan modificar cualquier usuario y los postulanes solo su informacion
    try {
      const response = await apiClient.post(`/auth/checkSignIn`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // signUp: async (datos) => {
  //   try {
  //     const response = await apiClient.post(`/auth/signUp`, datos);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
};

export default authServices;
