import { useEffect, useState} from 'react'
import resourcesServices from '../../services/resourcesServices'
import { errorMessage } from '../../errorHandler';

const Votacion = ({isTest}) => {
  const [running, setRunning] = useState(false);

  const timer = 5

  const [usuarios, setUsuarios] = useState([]);
  const [usuarioGanador, setUsuarioGanador] = useState({});
  const [usuariosGanadores, setUsuariosGanadores] = useState([]);
  const [mostrarGanador, setMostrarGanador] = useState(false);

  const [indice, setIndice] = useState(0);
  const [iniciarRecorrido, setIniciarRecorrido] = useState(false);

  useEffect(() => {
    setRunning(true)
  }, []);

  useEffect(() => {
    let intervalo;

    if (iniciarRecorrido) {
      intervalo = setInterval(() => {

        // Actualiza el índice para pasar al siguiente nombre
        setIndice((prevIndice) => {
          const nuevoIndice = (prevIndice + 1) % timer;

          // Detén el intervalo después de 5 recorridos
          if (nuevoIndice === 0) {
            clearInterval(intervalo);
            setIniciarRecorrido(false); // Restablece el estado para futuros recorridos
            setMostrarGanador(true);
            guardarGanador()
          }

          return nuevoIndice;
        });
      }, 1000); // 12000 milisegundos = 12 segundos (ajusta según tus necesidades)
    }

    // Limpia el intervalo cuando el componente se desmonta o cuando se detiene el recorrido
    return () => clearInterval(intervalo);
  }, [usuarios, indice, iniciarRecorrido]);


  const obtenerParticipantes = async () => {
    try {
      const data = await resourcesServices.obtenerResources(`multimedia_usuarios/postulantesSorteo${isTest ? '?test=1' : ''}`);

      setUsuarios(data.data.concat(data.data));
      setUsuarioGanador(data.data[data.data.length - 1])

    } catch (error) {
      errorMessage(error)
    }
  };

  const guardarGanador = async () => {
    try {

      const params = {
        ganador: `${isTest ? false : true}`
      }

      await resourcesServices.actualizarResource('multimedia_usuarios', usuarioGanador["id"], {multimedia_usuario: params});
    } catch (error) {
      errorMessage(error)
    }
  };

  const handleIniciarRecorrido = () => {
    // Inicia el recorrido al presionar el botón
    setIniciarRecorrido(true);
  };

  const telefonoParcial = (phone) => {
    return phone.substring(phone.length - 4)
  }

  const nuevoSorteo = () => {
    setUsuarios([])
    setUsuarioGanador({})
    setMostrarGanador(false)
  }

  const obtenerGanadores = async () => {
    try {
      if(usuariosGanadores && usuariosGanadores.length > 0){
        setUsuariosGanadores([]);
      } else {
        const data = await resourcesServices.obtenerResources(`multimedia_usuarios/postulantesSorteados${isTest ? '?test=1' : ''}`);
        setUsuariosGanadores(data.data);
      }


    } catch (error) {
      errorMessage(error)
    }
  };

  return (
    <div className='position-relative'>
      <div className="position-absolute z-3 top-0 end-0 w-50 vh-100 environment-3d">
        {
          running && (
            <div class="d-flex align-items-start text-center flex-column mb-3 h-100">

              <div className='p-2 bg-body-secondary w-100'> </div>

              {
                usuariosGanadores && usuariosGanadores.length > 0 && (
                  <div className='mt-auto text-light p-2 w-100 bg-warning p-2 bg-opacity-25 rounded-pill'>
                  {usuariosGanadores.map((ganador, index) => (
                    <div key={index} className='border d-inline-block' >
                      <img width={200} src={ganador["smallImageUrl"] || ganador["imageUrl"]} className="img-thumb m-1" />
                      <br/>{ganador["nombre_usuario"]} ({ganador["id_web"]})
                    </div>
                  ))}
                  </div>
                )
              }

              {(mostrarGanador || iniciarRecorrido) && (
                <div className='mt-auto text-light p-2 w-100 bg-warning p-2 bg-opacity-25 rounded-pill'>
                  {mostrarGanador && (
                    <>
                    <h3 className=''>Felicitaciones {usuarioGanador["nombre_usuario"]} ({usuarioGanador["id_web"]}) !!!</h3>
                    <div>
                      <img width={300} src={usuarioGanador["smallImageUrl"] || usuarioGanador["imageUrl"]} className="img-fluid" />
                    </div>
                    </>
                  )}

                  {iniciarRecorrido && (
                    <h3 className='p-2 text-light'>{timer - indice}</h3>
                  )}
                </div>
              )}


              {/* <div className='mt-auto p-2 w-100'>
                <div className='p-2'>

                </div>

              </div> */}

              <div class="mt-auto p-2 w-100 bg-warning p-2 bg-opacity-25 rounded-pill">
                <h4 className='p-2 text-light'>Hay {parseInt(usuarios.length/2)} participantes</h4>
                {usuarios.length > 0 ? (
                  <>
                    {
                      mostrarGanador ? (
                        <button onClick={nuevoSorteo} className='btn btn-success me-1' type='button'>Nuevo Sorteo</button>
                      ) : (
                        <button onClick={handleIniciarRecorrido} className='btn btn-success me-1' type='button'>Buscar Ganador</button>
                      )

                    }
                    <button onClick={() => obtenerGanadores()} className='btn btn-warning me-1' type='button'>{usuariosGanadores && usuariosGanadores.length > 0 ? 'Ocultar Ganadores' : 'Obtener Ganadores'}</button>
                  </>
                ):(
                  <button onClick={() => obtenerParticipantes()} className='btn btn-primary me-1' type='button'>Encontrar Participantes</button>
                )}
              </div>

              <div class="mt-auto bg-warning p-2 w-100"> </div>

            </div>
          )
        }
      </div>
    </div>
  );
}

export default Votacion;