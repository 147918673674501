import React from 'react';

const Presentacion = () => {
  return (
    <div className='text-start'>
      <h3 id="inicio">¡Bienvenidos a la segunda edición de Kizomba Patagonia 2024!</h3>

      <div className='block-kp'>
        Estamos emocionados de presentarles una experiencia de baile única en Neuquén, donde la pasión por la danza se fusiona con la energía de la Patagonia. Este año, nos complace anunciar que hemos ampliado nuestra oferta para incluir más ritmos y horas de baile.
      </div>

      <div className='block-kp'>
        En cada encuentro, ofreceremos una amplia variedad de estilos, desde el intensivo Kizomba para principiantes hasta el desafiante Urban Kiz y Fusion para bailarines intermedios y avanzados. Además, exploraremos la conexión entre Zouk y la bachata en una clase especial para los amantes de estos ritmos. ¡Y no olvidemos la importancia de la musicalidad en cada movimiento!
      </div>

      <div className='block-kp'>
        Cada fin de semana de Kizomba Patagonia estará lleno de aprendizaje, con más de 10 horas de capacitación impartidas por nuestros talentosos instructores. Pero no se trata solo de clases, ¡también habrá sociales para que puedas practicar y compartir tus pasos con otros bailarines apasionados!
      </div>

      <div className='block-kp'>
        No importa si eres un principiante absoluto o un bailarín experimentado, Kizomba Patagonia tiene algo para todos. ¡Únete a nosotros y déjate llevar por la magia de la danza en el corazón de la Patagonia!
      </div>

      <div className='block-kp'>
        ¡Reserva tu lugar ahora y prepárate para vivir una experiencia inolvidable en Kizomba Patagonia 2024!
      </div>
    </div>
  );
};

export default Presentacion;