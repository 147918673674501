import React from 'react';
import { Link } from "react-router-dom";

const MenuPagos = ({marcaActual}) => {
  return (
    <div className="btn-group mb-3" role="group" aria-label="Basic example">
      <Link className="btn btn-outline-secondary" to={`/cl/${marcaActual.url_name}/pagos`}>Pagos</Link>
      <Link className="btn btn-outline-secondary" to={`/cl/${marcaActual.url_name}/pago`}>Nuevo Pago</Link>
      <Link className="btn btn-outline-secondary" to={`/cl/${marcaActual.url_name}/medios-de-pagos`}>Medios de Pagos</Link>
      <Link className="btn btn-outline-secondary" to={`/cl/${marcaActual.url_name}/medio-de-pago`}>Nuevo Medio de Pago</Link>
    </div>
  );
};

export default MenuPagos;