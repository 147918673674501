import React, { useState, useEffect} from 'react';

/*
Si label no está definido, con la funcion convertSnakeCaseToText convierto el atributo name en label
*/
const TextAreaControl = ({ value, name, handleInput, label, type='text', height='auto' }) => {
  const [caption, setCaption] = useState('');

  useEffect(() => {
    if (label===undefined){
      if (name.includes('.')) {
        const [parentKey, childKey] = name.split('.');
        setCaption( convertSnakeCaseToText(childKey));
      } else {
        setCaption( convertSnakeCaseToText(name));
      }
    } else {
      setCaption(label)
    }
  }, [name]);

  const convertSnakeCaseToText = (snakeCaseString) => {
    const words = snakeCaseString.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  return (
    <div className="form-floating mb-3">
    <textarea
      type={type}
      name={name}
      style={{height: height}}
      value={value || ''}
      className="form-control"
      onChange={(e) => handleInput(e)}
      placeholder={caption}
      id={name} />
    <label htmlFor={name}>{caption}</label>
  </div>
  );
};

export default TextAreaControl;