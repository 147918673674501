import React from 'react';
import { Link } from "react-router-dom";
import fotoRoma from '../../assets/images/roma.jpg';
import fotoFlopy from '../../assets/images/flopy.jpg';

const Instructoras = () => {
  return (
    <div className='text-start mb-5'>
      <h1 id="instructoras">Instructoras</h1>

      <div className="row">
        <div className="col-sm-6 mb-3 mb-sm-0">

        <div className="card">
        <img src={fotoFlopy} className="card-img-top" alt="Roma" />
          <div className="card-body">
            <h5 className="card-title">Flopy Verni</h5>
            <p className="card-text">
              María Florencia Verni “Flopy Verni”<br />
              Argentina. Vive en Buenos Aires.<br /><br />

              Profesora Nacional de Educación Física<br />
              Instructora de Lambada Zouk, rítmos brasileros (axé) y Kizomba.<br /><br />

              Participó como Instructora en diversos workshops, festivales y congresos en el mundo como Río de Janeiro, Porto Seguro, Mina Gerais (Brasil), Montevideo, Colonia del Sacramento (Uruguay) Santiago de Chile (Chile), Maracay (Venezuela), París (Francia) etc.<br /><br />

              Fue parte de innumerables eventos en Argentina e impartío talleres en
              distintas partes localidades como Puerto Madryn, Trelew, Comodoro Rivadavia, Neuquén, Bahia Blanca, Necochea, Córdoba Capital, Rosario, etc.<br /><br />

              Jurado de la 1er competencia Latinoamericana de kizomba (Africandar) año 2015<br /><br />

              Organizadora de PASIÓN BAZOU, KIZZYOU, BS AS LIKE FESTIVAL 2014-2018 (festival internacional de Lambada y Kizomba), BAUW (Buenos Aires Urban Weekend) 2019<br /><br />
              Directora e instructora del “IK – Instructorado Kizomba” desde el 2017 / IO Intensivos Online 2020-2021<br /><br />

              Propietaria de la escuela de danza y artes escenicas “La Tribu Espacio”<br /><br />

              En 2022 fue la instructora del primer Kizomba Patagonia en Neuquén.

              <div className="text-center d-flex my-3">
                Instagram de &nbsp; <Link className='link-success link-offset-2 link-underline link-underline-opacity-25' target="_blank" to={`https://www.instagram.com/flopyverni/`}>Flopy Verni</Link>
              </div>
            </p>
          </div>

        </div>


        </div>
        <div className="col-sm-6">

        <div className="card">
          <img src={fotoRoma} className="card-img-top" alt="Roma" />
          <div className="card-body">
          <h5 className="card-title">Roma</h5>
            <p className="card-text">
              Romina Andrian “Roma”<br />
              Argentina. Vive en Neuquen.<br /><br />

              Bailarina, coreógrafa, cantante, bajista, docente, conductora<br /><br />

              Profesora nacional de arte dramático (INSA/IUPA)<br /><br />

              Profesora de Ritmos Caribeños<br /><br />

              Curso y membresía del MCC con Yoel Marrero, fundador del movimiento Casino para todos (NQN'MIAMI'CUBA)<br /><br />

              Directora de Baila Conmigo Eventos<br /><br />

              Profesora de Danzas urbanas de 7 a 12 años, Coreografía y de Salsa Principiantes y Avanzados<br /><br />

              Arteterapeuta<br /><br />

              Estudiante de Comedia Musical en la Conrado Villegas<br /><br />

              Dirección artística de las noches temáticas de Flame Disco (Pirkas - KMK - Gremio)<br /><br />

              Organización artística para diferentes eventos privados de la región como bodas, cumpleaños , inauguraciones, recepciones, presentaciones, actos, etc<br /><br />

              Profesora de Teatro en 1er año, expresión corporal en 2do año y coreografía en 3er año de la carrera de modelos en el Instituto Visso (2022-2023)<br /><br />

              Bajo y coros en Monkustrap (covers 60,70,80,90)<br /><br />

              Panelista en “Tardes de Primera” conducción Huguex Cabrera por CN 24/7 (2022-2024)


              <div className="text-center d-flex my-3">
                Instagram de &nbsp; <Link className='link-success link-offset-2 link-underline link-underline-opacity-25' target="_blank" to={`https://www.instagram.com/roma_baila_conmigo/`}>Roma</Link>
              </div>
            </p>
          </div>
        </div>



        </div>
      </div>


    </div>
  );
};

export default Instructoras;