import React, { useContext, useEffect, useState } from 'react';
import logoIvanMazzei from '../assets/images/logo-nuevo-1.png';
import { InputContext } from '../contexts/InputContext';
import ImmHome from '../components/ImmHome';
import ImmUsuario from '../components/ImmUsuario';
import ImmAceptaCondiciones from '../components/ImmAceptaCondiciones';
import Footer from '../components/Footer';
import HeaderNew from '../components/HeaderNew';
import AccesoTexto from '../components/AccesoTexto';

const IvanMazzeiMusica = () => {
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)

  useEffect(() => {
    checkSignIn()
  }, []);

  return (
    <div className="container pt-0 pb-3 bg-main text-center">
      {
        (authInformation.autenticado === true) ? (
          <>
            {
              (authInformation.rol === "Admin" || authInformation.rol === "Organizador") && (
                <HeaderNew />
              )
            }

            {authInformation.condiciones_aceptadas ? <ImmUsuario /> : <ImmAceptaCondiciones />}

            {
              (authInformation.rol === "Admin" || authInformation.rol === "Organizador") && (
                <Footer />
              )
            }
          </>
        ) : (
          <>
          <AccesoTexto />

          </>
        )
      }
    </div>
  );
};

export default IvanMazzeiMusica;