import React from 'react';

const Fechas = () => {
  return (
    <div className='text-start'>
      <h1 id="fechas">Fechas y Lugar</h1>

      <div className="card text-start mb-5">
        <div className="card-header">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <button className="nav-link active" id="marzo-tab" data-bs-toggle="tab" data-bs-target="#marzo-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><b>Encuentro 1</b> - 23 y 24 de Marzo</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="abril-tab" data-bs-toggle="tab" data-bs-target="#abril-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><b>Encuentro 2</b> - 27 y 28 de Abril</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="mayo-tab" data-bs-toggle="tab" data-bs-target="#mayo-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><b>Encuentro 3</b> - 18 y 19 de Mayo</button>
            </li>
          </ul>
        </div>

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="marzo-tab-pane" role="tabpanel" aria-labelledby="marzo-tab" tabindex="0">


            <div className="card bg-kizomba">
              {/* <div className="card-header">
                <b>Encuentro 1</b> - Sábado 23 y Domingo 24 de Marzo
              </div> */}
              <div className="card-body bg-kizomba-patagonia">
                <p className="card-text">
                  <h2>Sábado 23 de Marzo</h2>
                  <h5>en Morgan Bar Cervecero, ciudad de Neuquén</h5>
                  <ul className="list-group list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Intensivo de Kizomba - Nivel 1 de 3</div>
                        No es necesario tener conocimientos previos.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 5:00 horas</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Zouk (también aplicable a la bachata)</div>
                        Aplicable a la bachata y otros bailes que puedan fusionar con las técnicas de movimiento de cabeza, ondas, conducción, etc. <br />
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 1:30 horas</span>
                    </li>
                  </ul>

                  <h2>Domingo 24 de Marzo</h2>
                  <h5>en Salón Rainbow del Casino Magic, ciudad de Neuquén</h5>
                  <ul className="list-group list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Master 1 de 3</div>
                        Urban Kiz, Fusion, Tarraxo<br />
                        Es recomendable tener contacto previo con algún de estos géneros<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 3:00 horas</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Musicalidad - Llevar y Seguir</div>
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Roma
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 1:00 hora</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                      <div className="fw-bold text-uppercase">Zouk (también aplicable a la bachata)</div>
                        Aplicable a la bachata y otros bailes que puedan fusionar con las técnicas de movimiento de cabeza, ondas, conducción, etc. <br />
                        Dirigido a todos los niveles.
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 1:30 horas</span>
                    </li>
                  </ul>
                </p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>


          <div className="tab-pane fade" id="abril-tab-pane" role="tabpanel" aria-labelledby="abril-tab" tabindex="0">

            <div className="card bg-kizomba">
              {/* <div className="card-header">
                <b>Encuentro 2</b> - Sábado 27 y Domingo 28 de Abril
              </div> */}
              <div className="card-body bg-kizomba-patagonia">
                <p className="card-text">
                  <h2>Sábado 27 de Abril</h2>
                  <h5>lugar a definir, ciudad de Neuquén</h5>
                  <ul className="list-group list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Intensivo de Kizomba - Nivel 2 de 3</div>
                        No es necesario tener conocimientos previos.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 5:00 horas</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Zouk para Bachateros</div>
                        Aplicable a la bachata y otros bailes que puedan fusionar con las técnicas de movimiento de cabeza, ondas, conducción, etc. <br />
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: a definir</span>
                    </li>
                  </ul>

                  <h2>Domingo 28 de Abril</h2>
                  <h5>lugar a definir, ciudad de Neuquén</h5>
                  <ul className="list-group list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Master 2 de 3</div>
                        Urban Kiz, Fusion, Tarraxo<br />
                        Es recomendable tener contacto previo con algún de estos géneros<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 3:00 horas</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Musicalidad - Llevar y Seguir</div>
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Roma
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 1:00 hora</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                      <div className="fw-bold text-uppercase">Zouk (también aplicable a la bachata)</div>
                        Aplicable a la bachata y otros bailes que puedan fusionar con las técnicas de movimiento de cabeza, ondas, conducción, etc. <br />
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: a definir</span>
                    </li>
                  </ul>
                </p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>

          </div>


          <div className="tab-pane fade" id="mayo-tab-pane" role="tabpanel" aria-labelledby="mayo-tab" tabindex="0">

            <div className="card bg-kizomba">
              {/* <div className="card-header">
                <b>Encuentro 3</b> - Sábado 18 y Domingo 19 de Mayo
              </div> */}
              <div className="card-body bg-kizomba-patagonia">
                <p className="card-text">
                  <h2>Sábado 18 de Mayo</h2>
                  <h5>lugar a definir, ciudad de Neuquén</h5>
                  <ul className="list-group list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Intensivo de Kizomba - Nivel 3 de 3</div>
                        No es necesario tener conocimientos previos.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 5:00 horas</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Zouk para Bachateros</div>
                        Aplicable a la bachata y otros bailes que puedan fusionar con las técnicas de movimiento de cabeza, ondas, conducción, etc. <br />
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: a definir</span>
                    </li>
                  </ul>

                  <h2>Domingo 19 de Mayo</h2>
                  <h5>lugar a definir, ciudad de Neuquén</h5>
                  <ul className="list-group list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Master 3 de 3</div>
                        Urban Kiz, Fusion, Tarraxo<br />
                        Es recomendable tener contacto previo con algún de estos géneros<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: 3:00 horas</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Musicalidad - Llevar y Seguir</div>
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Roma
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: a definir</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold text-uppercase">Zouk para Bachateros</div>
                        Aplicable a la bachata y otros bailes que puedan fusionar con las técnicas de movimiento de cabeza, ondas, conducción, etc. <br />
                        Dirigido a todos los niveles.<br />
                        <b>Instructora:</b> Flopy Verni
                      </div>
                      <span className="badge bg-primary rounded-pill">Duración: a definir</span>
                    </li>
                  </ul>
                </p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>

          </div>
        </div>


      </div>









    </div>
  );
};

export default Fechas;