import { useEffect, useState } from 'react';
import placeholderImage from "../../assets/images/placeholder.jpg";

const UseProgressiveImage = (props) => {
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = props.src
    img.onload = () => setSourceLoaded(props.src)
  }, [props.src])


  return <span className='image' style={{transition: 'background-image 1s ease-in-out', background: `url(${ sourceLoaded || placeholderImage }) center/contain no-repeat`,}}></span>;
}

export default UseProgressiveImage;