import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import resourcesServices from '../services/resourcesServices';
import TextControl from './customizedInputs/TextControl';
import TextAreaControl from './customizedInputs/TextAreaControl';
import SelectControl from './customizedInputs/SelectControl';
import { InputContext } from '../contexts/InputContext';
import BotonVolver from './BotonVolver';
import { errorMessage } from '../errorHandler';

const PosteoForm = ({marcaActual}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [authInformation, setAuthInformation] = useContext(InputContext)

  const [posteo, setPosteo] = useState({});
  const [esNuevo, setEsNuevo] = useState(true);
  const [saving, setSaving] = useState(false);
  const [renderHTML, setRenderHTML] = useState(false);

  useEffect(() => {
    setEsNuevo( id === undefined )
    if(authInformation.autenticado && authInformation.rol === "Admin"){
      setRenderHTML(true)
      if(id){
        fetchPosteo(id)
      } else {
        nuevoPosteo()
      }
    }
  }, [id, authInformation]);


  const fetchPosteo = async (id) => {
    try {
      const data = await resourcesServices.obtenerResourcePorId('social_contents', id);
      setPosteo(data);
    } catch (error) {
      errorMessage(error)
    }
  };

  const nuevoPosteo = async (id) => {
    try {
      const data = await resourcesServices.nuevoResource('social_contents');
      setPosteo(data);
    } catch (error) {
      console.log('error', error)
    }
  };

  const guardarPosteo = async () => {
    try {
      setSaving(true)
      let data;

      const copiaPosteo = {...posteo};

      if(copiaPosteo.start_datetime){
        copiaPosteo.start_datetime = copiaPosteo.start_datetime.replace(/T/ig, ' ');
      }

      if(esNuevo){
        data = await resourcesServices.crearResource('social_contents', {social_content: copiaPosteo}, true);
        alert("Posteo creado")
        navigate(`/cl/${marcaActual.url_name}/posteos`);
      } else {
        data = await resourcesServices.actualizarResource('social_contents', copiaPosteo.id, {social_content: copiaPosteo}, true);
        setPosteo(data);
        alert("Datos actualizados")
      }

      setSaving(false)

    } catch (error) {
      setSaving(false)
      errorMessage(error)
    }
  };

  const handleInput = (e) => {
    const { name, value, checked } = e.target;

    setPosteo({...posteo,
      [name]: value
    })
  }

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setPosteo({...posteo,
      [name]: files[0]
    })
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    guardarPosteo()
  }


  return (
    <div>
      {renderHTML && (
        <>
          <h3 className='mt-3'>Editar posteo <BotonVolver /></h3>

          <div className="card">
            <div className="card-body px-0 pb-2 ms-3 me-3">
              <form onSubmit={handleFormSubmit}>

                {/* <pre>{JSON.stringify(ciudadSeleccionada, null, 2)}</pre> */}
                <div className="card mb-3">
                    <div className="card-body">
                    <h5 className="card-title">Suba una imagen (obligatorio)</h5>

                    {
                      posteo.mediaUrl && (
                        <img src={posteo.mediaUrl} className="img-fluid" />
                      )
                    }

                    <div className="mb-3">
                      <input placeholder="Adjunte una imagen" className="form-control" onChange={handleFileChange} name="media" type="file" id="media" />
                    </div>

                  </div>
                </div>

                <TextAreaControl
                    value={posteo.message}
                    name={'message'}
                    height={'150px'}
                    handleInput={handleInput}
                  />

                <div className='row'>
                  <div className='col-6'>
                    <TextControl
                        value={posteo.start_datetime}
                        name={'start_datetime'}
                        type={"datetime-local"}
                        handleInput={handleInput}
                      />
                  </div>
                  <div className='col-6'>
                    <TextControl
                      value={posteo.times}
                      name={'times'}
                      handleInput={handleInput}
                      label={'Veces/días que se va a publicar'}
                      type={'number'}
                      />
                  </div>
                </div>

                <button disabled={saving} className="btn btn-primary mt-3" type="submit">
                  {saving && (<span className="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>)}
                  <span role="status">Guardar</span>
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PosteoForm;