// FUENTE: https://www.youtube.com/watch?v=1PWDxgqLmDA
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Searcher from './pages/Searcher';
import Home from './pages/Home';
import EventosPasados from './pages/EventosPasados';
import Evento from './pages/Evento';
import Servicio from './pages/Servicio';
import Acceso from './pages/Acceso';
import CompartirFoto from './pages/CompartirFoto';
import IvanMazzeiMusica from './pages/IvanMazzeiMusica';
import PoliticasWS from './pages/PoliticasWS';
import Whatsapp from './pages/Whatsapp';
import InputProvider from './contexts/InputContext';
import Login from './pages/Login';
import Logout from './pages/Logout';
// import WSWA from './pages/WSWA';

// GALERIA MULTIMEDIA
import MultimediaUsuario from './pages/MultimediaUsuario';
import MultimediaGaleria from './pages/MultimediaGaleria';
import MultimediaPublicoImageBG from './pages/MultimediaPublicoImageBG';
import Avatar3D from './pages/multimedia/Avatar3D';
import PublicImages from './pages/multimedia/PublicImages';
import Mixing3D from './pages/multimedia/Mixing3D';

import RedirectFromShort from './pages/RedirectFromShort';
import KizombaPatagonia from './pages/KizombaPatagonia';
import Proximamente from './pages/Proximamente';
import ReceivedJson from './pages/ReceivedJson';

// import actionCable from "actioncable";

// const CableApp = {}
// CableApp.cable = actionCable.createConsumer('ws://localhost:3001/cable')

function App() {
  return (
    <InputProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/websockets" element={<WSWA cable={CableApp.cable} />} /> */}
          <Route path="/received-jsons" element={<ReceivedJson />} />
          <Route path="/eventos-pasados" element={<EventosPasados />} />
          <Route path="/evento/:id/:title" element={<Evento />} />
          <Route path="/pronto" element={<Proximamente />} />
          <Route path="/acceso" element={<Acceso />} />
          <Route path="/compartir-foto" element={<CompartirFoto />} />
          <Route path="/patagonia-interactiva" element={<Servicio />} />
          <Route path="/registro-de-marca" element={<Home />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/login/:uuid" element={<Login />} />

          {/* Kizomba patagonia 2024 */}
          <Route path="/kizomba-patagonia" element={<KizombaPatagonia />} />

          {/* Get the url from ShortedUrl */}
          <Route path="/s/:shorted_url" element={<RedirectFromShort />} />

          {/* Marca Blanca */}
          <Route path="/cl/:url_client" element={<IvanMazzeiMusica />} />
          <Route path="/cl/:url_client/:area" element={<IvanMazzeiMusica />} />
          <Route path="/cl/:url_client/:area/:id" element={<IvanMazzeiMusica />} />

          {/* Muestra de multimedia */}

          <Route path="/multimedia-usuario/:usuario_id" element={<MultimediaUsuario />} />


          {/* Muestra de MULTIMEDIA PUBLICO */}
          {/* <Route path="/multimedia-publico" element={<MultimediaPublico />} /> */}
          {/* <Route path="/multimedia-publico-video" element={<MultimediaPublicoVideo />} /> */}
          <Route path="/galeria-multimedia" element={<MultimediaGaleria />} />

          <Route path="/multimedia-publico-imagen" element={<MultimediaPublicoImageBG />} />
          <Route path="/multimedia-publico/avatar3d" element={<Avatar3D />} />

          <Route path="/multimedia-publico/public-images" element={<PublicImages />} />
          <Route path="/multimedia-publico/public-images/:tipo" element={<PublicImages />} />
          <Route path="/multimedia-publico/public-mixing" element={<Mixing3D />} />
          <Route path="/multimedia-publico/public-mixing/:tipo" element={<Mixing3D />} />



          <Route path="/searcher" element={<Searcher />} />
          <Route path="/whatsapp" element={<Whatsapp />} />
          <Route path="/politicas-de-privacidad" element={<PoliticasWS />} />
        </Routes>
      </BrowserRouter>
    </InputProvider>
  );
}

export default App;
