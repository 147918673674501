import React, { useState, useEffect} from 'react';

/*
Si label no está definido, con la funcion convertSnakeCaseToText convierto el atributo name en label
*/
const SelectControl = ({ value, options, name, handleInput, label, style }) => {
  const [caption, setCaption] = useState('');

  useEffect(() => {
    if (label===undefined){
      if (name.includes('.')) {
        const [parentKey, childKey] = name.split('.');
        setCaption( convertSnakeCaseToText(childKey));
      } else {
        setCaption( convertSnakeCaseToText(name));
      }
    } else {
      setCaption(label)
    }
  }, [name]);

  const convertSnakeCaseToText = (snakeCaseString) => {
    const words = snakeCaseString.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  return (
      <select 
        className="form-select mb-3" 
        value={value || ''} 
        name={name}
        style={style}
        onChange={(e) => handleInput(e)}
      >
        <option value={''}>{caption}</option>
        {options.map((option) => (
          option instanceof Object ? (
          <option key={option.id} value={option.id}>{option.nombre}</option>
          ) : (
            <option key={option} value={option}>{option}</option>
            )
          
        ))}
      </select>
  );
};

export default SelectControl;